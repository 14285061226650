import { Controller as StimulusController } from "@hotwired/stimulus"

/**
 * Stimulus controller to show/hide tab panels
 * E.G:
 * div{
 *   data-controller='tab-panel'
 *   data-tab-panel-active-tab-class='btn--tab-active'
 *   data-tab-panel-hidden-panel-class='display-none'
 * }
 *   div
 *     span.btn.btn--primary.btn--tab.btn--large.btn--tab-active{
 *       data-action='click->tab-panel#switchToPanel'
 *       data-tab-panel-target='tab'} Tab 1
 *     span.btn.btn--primary.btn--tab.btn--large.btn--tab-active{
 *        data-action='click->tab-panel#switchToPanel'
 *        data-tab-panel-target='tab'} Tab 2
 *   div data-tab-panel-target='panel'
 *     p Panel 1
 *   div.display-none data-tab-panel-target='panel'
 *     p Panel 2
 */
export default class TabPanelController extends StimulusController {
  static targets = ['tab', 'panel']

  static classes = ['activeTab', 'hiddenPanel']

  switchToPanel(event) {
    event.preventDefault()

    this._deactivateAllTabs()
    this._activateTab(event.target)

    this._hideAllPanels()
    this._showPanel(event.target)
  }

  _activateTab(tab) {
    tab.classList.add(this.activeTabClass)
  }

  _showPanel(tab) {
    const index = this.tabTargets.indexOf(tab)
    this.panelTargets[index].classList.remove(this.hiddenPanelClass)
  }

  _deactivateAllTabs() {
    this.tabTargets.forEach(tab => {
      tab.classList.remove(this.activeTabClass)
    })
  }

  _hideAllPanels() {
    this.panelTargets.forEach(panel => {
      panel.classList.add(this.hiddenPanelClass)
    })
  }
}
