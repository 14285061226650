'use strict';

addEventListener("turbo:before-render", (event) => {
  event.detail.render = (currentElement, newElement) => {
    const rootId = document.querySelector("meta[name='turbo-root-element-id']")?.getAttribute("content")
    const appRootElement = currentElement.querySelector(`#${rootId}`)
    const newAppRootElement = newElement.querySelector(`#${rootId}`)

    if (appRootElement && newAppRootElement) {
      currentElement.replaceChild(newAppRootElement, appRootElement)
    } else if (currentElement && newElement instanceof HTMLBodyElement) {
      currentElement.replaceWith(newElement)
    } else {
      document.documentElement.appendChild(newElement);
    }
  }
})
