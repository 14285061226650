import { Controller } from "@hotwired/stimulus"
import validations from "../helpers/validations"

export default class extends Controller {
  static targets = ['input', 'hint']

  confirm(event) {
    this.perform()
    if (this.hasHintTargets) event.stopImmediatePropagation()
  }

  perform() {
    this.hintTargets.forEach(hint => hint.remove())
    this.inputTargets.forEach(input => {
      const type = input.dataset.validationType
      const invalidMessage = input.dataset.validationInvalidMessage

      if (!validations[type]?.test(input.value)) {
        input.parentElement.appendChild(this.hint(invalidMessage))
      }
    })
  }

  hint(message) {
    const hint = document.createElement('span')
    hint.classList.add('form__error')
    hint.classList.add('danger')
    hint.dataset.validationTarget = 'hint'
    hint.innerText = message
    return hint
  }
}
