import ModalStep from './modal_step'
import { createElement } from '../../../helpers/dom_helpers'

class TextInputStep extends ModalStep {
  render() {
    super.render()
    this._renderInput()
  }

  input() {
    return this._input
  }

  _correctValue() {
    return this.stepData().input_value.toLowerCase() || this.stepData().default_input_value.toLowerCase()
  }

  _labelText() {
    return this.stepData().name_value || this.stepData().default_name_value
  }

  _onButtonClick(_event) {
    const inputValue = this.input().value
    const correctValueEntered = !this.stepData().requireInputTextMatch || inputValue.toLowerCase() === this._correctValue()

    if (correctValueEntered) {
      this._toggleState(correctValueEntered)
      super._onButtonClick()
    } else {
      this._toggleState(correctValueEntered)
    }
  }

  _toggleState(success) {
    this._clearState()
    let color = 'danger'
    let resultMessage = this._errorMessage()
    let renderIcon = this._renderErrorIcon.bind(this)

    if (success) {
      color = 'success'
      resultMessage = 'Success'
      renderIcon = this._renderAnimateCheckbox.bind(this)
    }

    this._resultMessage.classList.add(`font-${color}`)
    this.input().classList.add(color)
    this._resultMessage.innerText = resultMessage
    renderIcon()
  }

  _errorMessage() {
    return 'That\'s not quite right. Please try again.'
  }

  _clearState() {
    this._iconContainer.innerText = ''
    this._resultMessage.classList.remove('font-success')
    this._resultMessage.classList.remove('font-danger')
    this.input().classList.remove('success')
    this.input().classList.remove('danger')
  }

  _handleKeyUp(event) {
    if (event.key === 'Enter') {
      this._onButtonClick()
    }
  }

  _renderInput() {
    this._inputRow = createElement(this.modal().element(), 'div', 'flex items-center margin-top-sm')
    this._resultMessage = createElement(this.modal().element(), 'div', 'margin-top-sm font-xs')
    this.modal().element().insertBefore(this._inputRow, this.button())
    this.modal().element().insertBefore(this._resultMessage, this.button())
    this._input = createElement(this._inputRow, 'input', 'form__input position-relative')
    this._input.onkeypress = this._handleKeyUp.bind(this)
    this._iconContainer = createElement(this._inputRow, 'span', 'margin-left-sm flex items-center')
  }

  _renderAnimateCheckbox() {
    this._checkmarkContainer = createElement(this._iconContainer, 'div', 'loading-circle')
    this._checkmark = createElement(this._checkmarkContainer, 'div', 'checkmark hidden')

    setTimeout(() => {
      this._checkmarkContainer.classList.add('load-complete')
      this._checkmark.classList.remove('hidden')
      this._checkmark.classList.add('draw')
    }, 400)
  }

  _renderErrorIcon() {
    this._icon = createElement(this._iconContainer, 'span', 'material-icons material-icons-outlined material-icons--lg')
    this._icon.style.color = 'var(--color-danger)'
    this._icon.innerText='cancel'
  }
}

export default TextInputStep
