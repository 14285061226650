import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "text" ]

  copy() {
    this.sourceTarget.select()
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.textTarget.innerHTML = 'Copied'
    setTimeout(() => this.textTarget.innerHTML = 'Copy', 5000 )
  }

  select() {
    this.sourceTarget.select()
  }
}
