import ContextMenuController from './context_menu_controller'

export default class EmulationFormController extends ContextMenuController {
  static targets = [...ContextMenuController.targets, 'groupLabel', 'groupCheckbox', 'checkboxAll', 'checkboxAllLabel']

  connect() {
    super.connect()
    this.updateLabel()
    this._changed = false
  }

  // toggle the check-all checkbox
  toggleAll(event) {
    event.target.checked ?
      this.selectAll() :
      this.deselectAll()

    this.registerChanges()
  }

  selectAll() {
    this.groupCheckboxTargets.forEach(input => input.checked = true)
    this.checkboxAllLabelTarget.innerHTML = 'Deselect All'
  }

  deselectAll() {
    this.checkboxAllTarget.checked = false
    this.groupCheckboxTargets.forEach(input => input.checked = false )
    this.checkboxAllLabelTarget.innerHTML = 'Select All'
  }

  registerChanges() {
    this._changed = true
  }

  leave() {
    if(this._changed) {
      this.updateLabel()
      this.submit()
    }
  }

  updateLabel() {
    const count = this.groupCheckboxTargets.reduce((total, element) => {
      if (element.checked) { return total + 1 } else { return total }
    }, 0)

    let label
    if (count > 0) {
      label = `Groups (${count})`
    } else {
      label = 'Groups'
    }

    this.groupLabelTarget.innerHTML = label
  }

  submit() {
    this.element.submit()
  }

  toggleMenu(event) {
    super.toggle(event)
  }

  closeMenu(event) {
    return super.hide(event)
  }
}
