'use strict';

import csrfToken from "../lib/csrfToken"

document.addEventListener('electron:saveSteps', async (event) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'text/vnd.turbo-stream.html',
    'X-CSRF-Token': csrfToken(),
  }

  const response = await fetch(`/admin/published_tutorials/${event.detail.tutorialId}/save_steps`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(event.detail.steps)
  })

  const html = await response.text()

  return Turbo.renderStreamMessage(html)
})
