import ElementData from './element_data'

export default class InputElementData extends ElementData {
  details() {
    const tagType = this.target.type

    if (tagType == 'password') {
      this._tagName = 'input-password'
    } else if (tagType == 'radio') {
      this._tagName = 'input-radio'
    } else if (tagType == 'checkbox') {
      this._tagName = 'input-checkbox'
    } else if (tagType == 'button' || tagType == 'submit') {
      this._tagName = "input-button"
      this._elementName = this.target.value
    } else if (tagType == 'file') {
      this._tagName = "input-button"
      this._elementName = this.titleOrText()
    } else {
      this._tagName = 'input-text'
    }
    this._elementInput = "none";

    if (!this.elementNameIsSet) {
      this._elementName = this.findInputName()
    }

    return this.result
  }

  findInputName() {
    if (this.target.nextElementSibling && this.target.nextElementSibling.tagName.toLowerCase() == "label") {
      return this.target.nextElementSibling.textContent
    } else if (this.target.previousElementSibling && this.target.previousElementSibling.tagName.toLowerCase() == "label") {
      return this.target.previousElementSibling.textContent
    } else if (this.target.placeholder != "") {
      return this.target.placeholder
    } else {
      return this.findLabelText()
    }
  }
}
