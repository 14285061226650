import CaptureController from "./capture_controller.js"

export default class UploadController extends CaptureController {
  static targets = ['status', 'position', 'stepId', 'signedId', 'xPath', 'elementTag', 'signedScreenshotId']
  static values = {
    stepId: String,
    stepXpath: String,
    stepElementTag: String,
    stepPosition: Number,
    directUploadsUrl: String,
    stepUrl: String
  }

  connect() {
    this.process()
  }

  async process() {
    /**
     * blob: {
     *   filename,
     *   content_type,
     *   byte_size,
     *   checksum
     * }
     */
    const blob = await window.electronAPI.getBlobDetails(this.stepIdValue)
    const screenshot = await window.electronAPI.getImageDetails(this.stepIdValue)

    const htmlError = await this.processUpload(blob, this.signedIdTarget, 'upload')
    const screenshotError = await this.processUpload(screenshot, this.signedScreenshotIdTarget, 'upload-screenshot')
    const error = htmlError || screenshotError

    if (error) {
      this._displayError(error)
    } else {
      this._afterUpload()
    }
  }

  async uploadPrep(blob) {
    const response = await fetch(this.directUploadsUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this._csrfToken,
      },
      body: JSON.stringify({blob}),
    })

    return response.json()
  }

  get _csrfToken() {
    return document.querySelector('meta[name=csrf-token]')?.content
  }

  async processUpload(file, target, action) {
    /**
     * direct_upload: {
     *   url,
     *   headers
     * }
     **/
    const {signed_id, direct_upload} = await this.uploadPrep(file)
    target.value = signed_id

    const { error } = await window.electronAPI.upload(this.stepIdValue, direct_upload, action)
    return error
  }

  _afterUpload() {
    this.statusTarget.innerText = 'saving in progress...'
    this.positionTarget.value = this.stepPositionValue
    this.stepIdTarget.value = this.stepIdValue
    this.xPathTarget.value = this.stepXpathValue
    this.elementTagTarget.value = this.stepElementTagValue
    this.dispatch('ready')
  }

  _displayError({code, message}) {
    this.statusTarget.innerText = 'Upload failed - Step was not saved'
    this.statusTarget.classList.add('form__error')
    this.statusTarget.title = message
    this.dispatch('error', {detail: {code, message}})
  }
}
