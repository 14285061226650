import { Controller as StimulusController } from "@hotwired/stimulus"
import csrfToken from '../lib/csrfToken'

/**
 * Stimulus controller to update UI to show progress for a translation job
 */
export default class JobStatusController extends StimulusController {
  static targets = ['status', 'hiddenContentWhileRunning']
  static values = {
    path: String,
  }

  connect() {
    this.updateStatus()
  }

  async updateStatus() {
    const response = await this.fetchData()
    const data = await response.json()
    this.statusTarget.innerHTML = data.status_text

    if (data.status === 'running') {
      setTimeout(() => this.updateStatus(), 30000)
    }
  }

  async fetchData() {
    return fetch(
      `${this.pathValue}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      },
    )
  }
}
