import ElementData from './element_data'

export default class LabelElementData extends ElementData {
  details() {
    this._tagName = 'label'
    this._elementName = this.target.textContent

    const labelAssociatedElement = this.iframeDocument.querySelector(`[id="${this.target.getAttribute('for')}"]`)
    if (labelAssociatedElement) {
      if (labelAssociatedElement.type == 'radio') {
        this._tagName = 'input-radio'
      } else if (labelAssociatedElement.type == 'checkbox') {
        this._tagName = 'input-checkbox'
      }
    }

    return this.result
  }
}
