import { Controller as StimulusController } from "@hotwired/stimulus"

export default class FileSelectorController extends StimulusController {
  static targets = ['fileText', 'removeButton']

  updateText(event) {
    const fileName = event.target.value.split('\\').pop()
    this.fileTextTarget.innerHTML = this.truncate(fileName)
  }

  truncate(string) {
    if (string.length < 33) { return string }

    const start = string.substr(0, 20)
    const end = string.substr(string.length - 10, 10)
    return `${start}...${end}`
  }

  resetImage() {
    this.removeButtonTarget.classList.toggle('btn--danger--active')
    if ( this.removeButtonTarget.classList.contains('btn--danger--active') == true ) {
      this.fileTextTarget.innerHTML = 'No Image Selected'
    } else {
      this.fileTextTarget.innerHTML = this.removeButtonTarget.dataset['imagename']
    }
  }
}