import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['iframe']
  static values = {
    x: Number,
    y: Number,
    isCustom: Boolean,
    ready: Boolean,
  }

  prepare() {
    if (this.isCustomValue) window.scrollTo(this.xValue, this.yValue)

    this.readyValue = true
  }
}
