import SortableController from 'stimulus-sortable'
import SortableJS from 'sortablejs'

/**
 * Abstract base class stimulus controller to allow drag-n-drop ordering and nesting of
 * library items in a tree-like fashion
 * @abstract
 */
export default class SortableLibraryItemsController extends SortableController {
  static values = { resourceName: String }

  get emptyFolderClass() { return 'empty' }

  connect() {
    this.sortable = this.createSortableElement(this.element)
  }

  /**
   * @param  {HTMLNode} element The element you want to attach a SortableJS object to
   * @returns {SortableJS} returns the SortableJS object it attached to the element with options applied
   */
  createSortableElement(element) {
    return new SortableJS(element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  /**
   * @param  {HTMLNode} dragged The item currently being dragged
   * @param  {HTMLNode} to The folder you are hovering over
   * @returns {boolean} Return whether or not the dragged item is allowed to be placed into the folder you are hovering over
   * @description Called as you are moving a dragged element. Determines where you are allowed to drop it.
   */
  onMove({ dragged, to }) {
    const targetAllowsSubfolder = to.dataset.allowSubFolder === 'true'
    const currentItemType = dragged.dataset.itemType

    const movingFolder = currentItemType === 'Folder'
    const movingLearningModule = !movingFolder

    if (movingFolder && !targetAllowsSubfolder) { return false }

    return (movingFolder && !this._containsLearningModules(to)) || (movingLearningModule && !this._containsFolders(to))
  }

  /**
   * @param   {HTMLNode} folderElement The element you are currently inspecting
   * @returns {boolean} Return whether the current folder element contains learning modules
   * @description Determine if the contents of the current folder are learning modules
   */
  _containsLearningModules(folderElement) {
    return this._childItemTypes(folderElement).filter(type => type !== 'Folder' && type !== undefined).length > 0
  }

  /**
   * @param   {HTMLNode} folderElement The element you are currently inspecting
   * @returns {boolean} Return whether the current folder element contains sub folders
   * @description Determine if the contents of the current folder are more folders
   */
  _containsFolders(folderElement) {
    return this._childItemTypes(folderElement).includes('Folder')
  }

  /**
   * @param   {HTMLNode} folderElement The element you are currently inspecting
   * @returns {boolean} Return the item types of the current folder elements children
   * @description Get the item types of all the current folders children
   */
  _childItemTypes(folderElement) {
    return Array.from(folderElement.children).map(item => item.dataset.itemType)
  }

  /**
   * @param  {HTMLNode} {item The element you dragged
   * @param  {Integer} newIndex The index within the to element of where you dropped the dragged element
   * @param  {HTMLNode} to The folder you dropped the dragged element into
   * @param  {HTMLNode} from} The folder the dragged element came from
   * @description Called when you drop a dragged element. Makes ajax request to persist the change on the server
   */
  onEnd({ item, newIndex, from , to}) {
    throw new Error('Must override')
  }


  _updateFolderEmptyStatus(folder) {
    if (folder.children.length === 0) {
      folder.classList.add(this.emptyFolderClass)
    } else {
      folder.classList.remove(this.emptyFolderClass)
    }
  }

  _updateCreateSubFolderButton(folderElement) {
    if (!folderElement.parentElement.previousElementSibling) {
      return
    }

    const createSubFolderButton = folderElement.parentElement.previousElementSibling.querySelector('.context-menu button[data-id="create_sub_folder"]')
    if (!createSubFolderButton) { return }

    if (this._containsLearningModules(folderElement)) {
      createSubFolderButton.setAttribute('disabled', true)
    } else {
      createSubFolderButton.removeAttribute('disabled')
    }
  }

  get defaultOptions() {
    return {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: 'library',
      ghostClass: 'is-dragged',
      onMove: this.onMove.bind(this),
      onEnd: this.onEnd.bind(this),
      filter: '.learning-content-card.learning-content-card--ignored', // Selectors that do not lead to dragging (String or Function)
      preventOnFilter: false, // Call `event.preventDefault()` when triggered `filter`
    }
  }
}
