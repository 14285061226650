import { Controller as StimulusController } from "@hotwired/stimulus"

export default class StepEditorImageHotspot extends StimulusController {
  tempHotspot = { x: 0, y: 0, width: 0, height: 0 }

  static targets = ['canvas', 'hotspot']
  static values = {
    hotspot: Object,
    listening: {
      type: Boolean,
      default: false,
    },
    capturing: {
      type: Boolean,
      default: false,
    },
    saved: {
      type: Boolean,
      default: true,
    }
  }

  renderHotspot() {
    Object.assign(this.tempHotspot, this.translateCoordinates(this.hotspotValue))
    this.updateHotspot()
  }

  captureStart(event) {
    if (!this.listeningValue) return

    this.savedValue = false
    this.capturingValue = true

    this.tempHotspot.x = event.offsetX
    this.tempHotspot.y = event.offsetY

    this.updateHotspot()
  }

  captureSize(event) {
    if (!this.listeningValue) return
    if (!this.capturingValue) return

   this.tempHotspot.width = event.offsetX - this.tempHotspot.x
   this.tempHotspot.height = event.offsetY - this.tempHotspot.y

   this.updateHotspot()
  }

  captureStop() {
    if (!this.listeningValue) return
    if (!this.capturingValue) return

    this.capturingValue = false

    this.saveHotspot()
    this.resetTempHotspot()
  }

  editStart() {
    this.listeningValue = true
    this.capturingValue = false
  }

  editStop() {
    this.listeningValue = false
    this.capturingValue = false
  }

  updateHotspot() {
    this.hotspotTarget.style.left = this.tempHotspot.x + 'px'
    this.hotspotTarget.style.top = this.tempHotspot.y + 'px'
    this.hotspotTarget.style.width = this.tempHotspot.width + 'px'
    this.hotspotTarget.style.height = this.tempHotspot.height + 'px'

    this.hotspotTarget.classList.remove('display-none')
  }

  saveHotspot() {
    this.hotspotValue = {
      x: this.tempHotspot.x - this.canvasImageOffsetX(),
      y: this.tempHotspot.y - this.canvasImageOffsetY(),
      width: this.tempHotspot.width,
      height: this.tempHotspot.height
    }
    this.savedValue = true

    this.dispatch('update', {
      prefix: 'hotspot',
      detail: {
        xPath: "//*[@id='__IMAGE_HOTSPOT__']",
        ...this.hotspotValue
      },
    })
  }

  resetTempHotspot() {
    this.tempHotspot = {x: 0, y: 0, width: 0, height: 0}
  }

  translateCoordinates({x, y, width, height}) {
    const xTransform = this.savedValue ? this.canvasImageOffsetX() : 0
    const yTransform = this.savedValue ? this.canvasImageOffsetY() : 0

    return {
      x: x + xTransform,
      y: y + yTransform,
      width,
      height,
    }
  }

  canvasImageOffsetX() {
    return Number(this.element.dataset.canvasImageOffsetX) || 0
  }

  canvasImageOffsetY() {
    return Number(this.element.dataset.canvasImageOffsetY) || 0
  }
}