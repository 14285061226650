import { Controller as StimulusController } from "@hotwired/stimulus"

export default class FileAttachmentController extends StimulusController {
  static targets = ['filenames']

  accepted(event) {
    const { attachments } = event.detail
    this.filenamesTarget.innerHTML = attachments.map(a => a.file.name).join('<br />')
  }
}
