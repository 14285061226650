export default class DirectUploadProgress {
  removalTimeout
  progressIndicator

  constructor(element, removalTimeout = 500) {
    this.removalTimeout = removalTimeout
    this.progressIndicator = document.createElement('progress')
    element.appendChild(this.progressIndicator)
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this.showUploadProgress.bind(this))
  }

  showUploadProgress(event) {
    this.progressIndicator.value = event.loaded
    this.progressIndicator.max = event.total
    if (event.total <= event.loaded) this.scheduleSelfRemoval()
  }

  scheduleSelfRemoval() {
    setTimeout(this._selfRemoval.bind(this), this.removalTimeout)
  }

  _selfRemoval() {
    this.progressIndicator.remove()
  }
}