import { Controller as StimulusController } from "@hotwired/stimulus"
import { getXPath, getNodeAtXPath } from "../../helpers/xpathHelper";
import ElementDataMapper from "../../lib/step_editor_instructions/element_data_mapper";

export default class StepEditorHtmlHotspot extends StimulusController {
  static targets = ['iframe', 'hotspot']
  static values = { xpath: String }

  _currentHotspot = undefined
  _shouldMoveHotspot = true

  connect() {
    this.updateXpath = this.updateXpath.bind(this)
    this.placeHotspot = this.placeHotspot.bind(this)
    this.moveCurrentHotspot = this.moveCurrentHotspot.bind(this)
  }

  contentIsLoaded() {
    this.resetEventListeners()

    if (!this.hasXpathValue) return

    this.currentHotspot = getNodeAtXPath(this.xpathValue, this.iframeDocument)
    this.placeHotspot()
  }

  updateXpath({target}) {
    this.stop()
    this.xpathValue = getXPath(target)
    this.currentHotspot = target
    this.placeHotspot()

    const details = ElementDataMapper.getDetails(target.tagName.toLowerCase(), target, this.iframeDocument)
    this.dispatch('update', { detail: { xPath: this.xpathValue, ...details }, prefix: 'hotspot' })
  }

  start() {
    this.iframeBody.addEventListener('click', this.updateXpath)
    this.iframeBody.addEventListener('mouseover', this.placeHotspot, false)
    this._shouldMoveHotspot = false
  }

  stop() {
    this.iframeBody.removeEventListener('click', this.updateXpath)
    this.iframeBody.removeEventListener('mouseover', this.placeHotspot, false)
    this._shouldMoveHotspot = true
    this.placeHotspot()
  }

  placeHotspot({target} = this.currentHotspot) {
    if (!target) return

    const {x, y, width, height} = this.translateCoordinates(target.getBoundingClientRect())
    this.hotspotTarget.style.left   = x + 'px'
    this.hotspotTarget.style.top    = y + 'px'
    this.hotspotTarget.style.width  = width + 'px'
    this.hotspotTarget.style.height = height + 'px'

    this.showHotspot()
  }

  showHotspot() {
    this.hotspotTarget.classList.remove('display-none')
  }

  resetEventListeners() {
    this.iframeWindow.removeEventListener('scroll', this.moveCurrentHotspot, true)
    this.iframeWindow.removeEventListener('resize', this.moveCurrentHotspot, true)
    this.iframeWindow.addEventListener('scroll', this.moveCurrentHotspot, true)
    this.iframeWindow.addEventListener('resize', this.moveCurrentHotspot, true)
  }

  translateCoordinates({x: localX, y: localY, width, height}) {
    const {x: offsetX, y: offsetY} = this.iframeTarget.getBoundingClientRect()
    return({
      x: Math.floor(localX + offsetX),
      y: Math.floor(localY + offsetY),
      width,
      height
    })
  }

  moveCurrentHotspot() {
    if (this._shouldMoveHotspot) this.placeHotspot()
  }

  get iframeWindow() {
    return this.iframeTarget.contentWindow
  }

  get iframeDocument() {
    return this.iframeTarget.contentDocument
  }

  get iframeBody() {
    return this.iframeDocument.body
  }

  get currentHotspot() {
    return { target: this._currentHotspot }
  }

  set currentHotspot(element) {
    if (!element) return

    this._currentHotspot = element
  }
}
