import { Controller as StimulusController } from "@hotwired/stimulus"

/**
 * Stimulus controller that enables checkbox fields to automatically
 * submit the form when they change values
 */
export default class LiveCheckboxController extends StimulusController {
  static targets = ['checkbox']

  initialize () {
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
  }

  connect() {
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.toggleCheckbox))
  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.toggleCheckbox))
  }

  toggleCheckbox(_event) {
    this.element.submit()
  }
}
