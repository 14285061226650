function setElementLocation(element, left, top, right) {
  if (left) {
    element.style.right = ''
    element.style.left = numberToPixels(left)
  } else if (right) {
    element.style.left = ''
    element.style.right = numberToPixels(right)
  }

  element.style.top = numberToPixels(top)
}

function setElementDimensions(element, width, height) {
  element.style.width = numberToPixels(width)
  element.style.height = numberToPixels(height)
}

function numberToPixels(number) {
  return number + 'px'
}

function numberFromPixels(string) {
  if (string === '') { return 0 }
  return parseFloat(string.replace('px', ''))
}

function createElement(rootElement, tagName, className) {
  const element = rootElement.appendChild(document.createElement(tagName))
  if (className) { element.className = className }
  return element
}

function toggleVisible(element, override) {
  if (!element) return
  const isVisible = !element.classList.contains('hidden')
  if (override === isVisible) return
  element.classList.toggle('hidden', isVisible)
}

function toggleDisplayNone(element, override) {
  if (!element) return
  const isVisible = !element.classList.contains('display-none')
  if (override === isVisible) return
  element.classList.toggle('display-none', isVisible)
}

function animate(node, animationName) {
  node.classList.add('animate__animated')
  node.classList.add(`animate__${animationName}`)

  setTimeout(() => {
    node.classList.remove('animate__animated')
    node.classList.remove(`animate__${animationName}`)
  }, 1000)
}

export {
  setElementLocation,
  setElementDimensions,
  numberToPixels,
  numberFromPixels,
  toggleVisible,
  toggleDisplayNone,
  createElement,
  animate
}
