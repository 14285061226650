import { Controller as StimulusController } from "@hotwired/stimulus"
import csrfToken from '../lib/csrfToken'

/**
 * Stimulus controller to count characters that are untranslated in a certain language for a certain organization
 */
export default class TranslationCharacterCountController extends StimulusController {
  static targets = ['input', 'count']

  get locale() { return this.inputTarget.value }

  connect() {
    this.updateCount()
  }

  async updateCount() {
    if (this.locale === '') {
      this._hideSpinner()
      return this.countTarget.innerHTML = '0 characters'
    }

    this._showSpinner()
    const response = await this.fetchData()
    const data = await response.json()
    this._hideSpinner()
    this.countTarget.innerHTML = `${(data.count / 1_000_000).toFixed(2)} million characters`
  }

  async fetchData() {
    return fetch(
      `${this.inputTarget.dataset.path}?locale=${this.locale}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      }
    )
  }

  _showSpinner() {
    this.countTarget.innerHTML = ''
    this.countTarget.classList.add('loading-spinner', 'margin-left-lg')
    this.countTarget.classList.remove('margin-left-xs')
  }

  _hideSpinner() {
    this.countTarget.classList.remove('loading-spinner', 'margin-left-lg')
    this.countTarget.classList.add('margin-left-xs')
  }
}
