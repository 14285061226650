import { Controller } from "@hotwired/stimulus"

export default class GuideImageStepController extends Controller {
  static targets = [
    "form",
    "status",
    "window",
    "xInput",
    "yInput",
    "isCustomInput",
  ]

  ready() {
    this.statusTarget.innerText = "Ready"
  }

  startSave() {
    this.dispatch('save', { target: this.windowTarget })
  }

  startReset() {
    this.dispatch('reset', { target: this.windowTarget })
  }

  save({detail: { x, y, isCustom }}) {
    this.xInputTarget.value = x
    this.yInputTarget.value = y
    this.isCustomInputTarget.value = isCustom

    this.formTarget.requestSubmit()
  }
}
