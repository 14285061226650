import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import DirectUploadProgress from "../helpers/directUploadProgress"

export default class extends Controller {
  static targets = ["fileInput", "progressContainer"]
  static values = { url: String }

  chooseFiles() {
    this.fileInputTarget.click()
  }

  process(event) {
    event.preventDefault()
    let files
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else {
      files = event.target.files
    }

    Array.from(files).forEach(this.upload.bind(this))
  }

  upload(file) {
    const progressContainer = this.hasProgressContainerTarget ? this.progressContainerTarget : this.element
    const upload = new DirectUpload(file, this.urlValue, new DirectUploadProgress(progressContainer))
    upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        this.dispatch('success', {detail: { blob }})
      }
    })
  }
}