import { Controller as StimulusController } from "@hotwired/stimulus"
import csrfToken from "../lib/csrfToken";

/**
 * Stimulus controller that does live translation magic
 */

export default class LiveTranslationController extends StimulusController {
  #url = '/api/v1/live_translation'
  static targets = ['translate']

  connect() {
    this.startTranslation();
  }

  startTranslation() {
    this.translateTargets.forEach(async el => {
      const payload = el.dataset.hasOwnProperty('liveTranslationAttributes') ? el.dataset.liveTranslationAttributes : JSON.stringify({html_content: el.innerHTML});
      const response = await this.#translateAndCache(payload)

      if (response?.translated_content === undefined) {
        return
      }

      el.innerHTML = null
      el.insertAdjacentHTML('afterbegin', response.translated_content)
      el.removeAttribute('data-live-translation-target')
      el.removeAttribute('data-live-translation-attributes')
    })
  }

  async #translateAndCache(payload) {
    try {
      const response =  await fetch(this.#url,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken(),
          },
          body: payload,
        },
      )
      return await response.json()
    } catch (e) {
      console.log(e)
    }
  }
}
