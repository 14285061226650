import { Controller } from "@hotwired/stimulus"
import csrfToken from '../lib/csrfToken'

export default class PrinterController extends Controller {
  static values = {
    path: String,
    statusPath: String,
  }

  initialize() {
    this.reportPrintActivity = this.reportPrintActivity.bind(this)
  }

  connect() {
    window.addEventListener('afterprint', this.reportPrintActivity)
  }

  disconnect() {
    window.removeEventListener('afterprint', this.reportPrintActivity)
  }

  async print() {
    try {
      const response = await this.checkPrintStatus()
      if(response.printing_enabled) {
        document.body.classList.add('show-print-content')
        await window.print()
      }
      else {
        location.reload()
      }
    }
    catch(error) {
      console.error(error)
    }
    finally {
      document.body.classList.remove('show-print-content')
    }
  }

  async checkPrintStatus() {
    const response = await fetch(
      this.statusPathValue,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      },
    )
    return await response.json()
  }

  async reportPrintActivity() {
    await fetch(
      this.pathValue,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      },
    )
  }
}
