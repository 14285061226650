import RevealController from 'stimulus-reveal-controller'

/**
 * Stimulus controller to add collapse/expand behavior to an element
 */
export default class AccordionController extends RevealController {
  connect() {
    super.connect()

    if (this.element.dataset.accordionBeginExpanded === 'true') {
      this.itemTargets.forEach(item => item.classList.toggle(this.class))
      this._scrollToFolder()
    }
  }

  toggle(event) {
    if (this.isContextMenuEvent(event) || this.isInputFieldEvent(event) || this.isCheckbox(event)) {
      return
    }

    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  open(event) {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
    }

  isContextMenuEvent(event) {
    return event.target.closest('.context-menu')
  }

  isInputFieldEvent(event) {
    return event.target.closest('input')
  }

  isCheckbox(event) {
    return event.target.closest('.form__group--checkbox')
  }

  _scrollToFolder() {
    const deconstructedUrl = window.location.href.split('#')
    const scrollToId = deconstructedUrl[deconstructedUrl.length - 1]
    if (deconstructedUrl.length > 1 && document?.querySelector(`#${scrollToId}`)) {
      const elementToScrollTo = document.querySelector(`#${scrollToId}`)
      elementToScrollTo.classList.remove('scrolled-to')
      elementToScrollTo.classList.add('scrolled-to')
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }
}
