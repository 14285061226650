import TdElementData from './td_element_data'
import SelectElementData from './select_element_data'
import LabelElementData from './label_element_data'
import BodyElementData from './body_element_data'
import InputElementData from './input_element_data'
import DefaultElementData from './default_element_data'

export default class ElementDataMapper {
  static klassMap = {
    'td': TdElementData,
    'select': SelectElementData,
    'label': LabelElementData,
    'body': BodyElementData,
    'input': InputElementData,
    'textarea': InputElementData,
  }

  static getDetails(elementType, target, iframeDocument) {
    const klass = this.klassMap[elementType]
    let instance
    if (klass) {
      instance = new klass(target, iframeDocument)
    } else {
      instance = new DefaultElementData(target, iframeDocument)
    }

    return instance.details()
  }
}
