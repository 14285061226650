import { Controller as StimulusController } from "@hotwired/stimulus"
import CookiesEuBanner from 'cookies-eu-banner'

export default class CookieBannerController extends StimulusController {
  connect() {
    this.banner = new CookiesEuBanner(() => {}, true)

    this.cleanupStyleAttributes()
  }

  // remove unnecessary style attribute so that other display attributes can be used in the CSS
  cleanupStyleAttributes() {
    if (!this.banner.hasConsent()) {
      document.getElementById('cookies-eu-banner').style.display = null
    }
  }
}
