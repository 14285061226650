import { Controller as StimulusController } from "@hotwired/stimulus"

export default class BulkDestroyController extends StimulusController {
  static targets = ['checkbox', 'step', 'form']

  static values = {
    path: String
  }

  showCheckboxes() {
    this.stepTargets.forEach((step) => {
      step.classList.add('bulk-destroy')
    })
  }

  hideCheckboxes() {
    this.stepTargets.forEach((step) => {
      step.classList.remove('bulk-destroy')
    })

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  toggle(e) {
    const id = e.target.value
    const hiddenField = document.querySelector(`input[type="hidden"][value="${id}"]`)

    if (hiddenField) {
      hiddenField.remove()
    }
    else {
      this.select(id)
    }
  }

  select(id) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'tutorial_step_ids[]'
    input.value = id
    this.formTarget.appendChild(input)
  }
}
