import { Controller as StimulusController } from "@hotwired/stimulus"

export default class FolderManagerController extends StimulusController {
  static targets = ['newFolderFormButton', 'newFolderForm']

  submit(event) {
    // On click away, event is focusout with sourceCapabilities of touch event which them triggers submit
    // On enter, submit happens, then focusout with null sourceCapabilities which triggers submit again
    // This prevents double submitting when sourceCapabilities is null a.k.a Enter was hit.
    if (event.sourceCapabilities != null) {
      this.newFolderFormTarget.requestSubmit()
    }
  }
}
