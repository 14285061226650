import { Controller as StimulusController } from "@hotwired/stimulus"

export default class StepEditorMenu extends StimulusController {
  static targets = [
    'editor',
    'hotspotStartButton',
    'hotspotStopButton',
    'editStartButton',
    'editStopButton',
    'popupStartButton',
    'popupStopButton',
    'savedIndicator',
    'popupStartAnchorButton',
    'popupStopAnchorButton',
  ]

  contentIsLoaded() {
    if (this._contentHasPopups) {
      this.popupStartButtonTarget.classList.remove('display-none')
      this.popupStartAnchorButtonTarget.classList.remove('display-none')
    }
  }

  editStart() {
    this.hotspotStop()
    this.popupStop()
    this.popupAnchorStop()
    this.dispatch('edit-start', { target: this.editorTarget })

    this.editStartButtonTarget.classList.add('display-none')
    this.editStopButtonTarget.classList.remove('display-none')
  }

  editStop() {
    this.dispatch('edit-stop', { target: this.editorTarget })

    this.editStartButtonTarget.classList.remove('display-none')
    this.editStopButtonTarget.classList.add('display-none')
  }

  hotspotStart() {
    this.editStop()
    this.popupStop()
    this.popupAnchorStop()
    this.dispatch('hotspot-start', { target: this.editorTarget })

    this.hotspotStartButtonTarget.classList.add('display-none')
    this.hotspotStopButtonTarget.classList.remove('display-none')
  }

  hotspotStop() {
    this.dispatch('hotspot-stop', { target: this.editorTarget })

    this.hotspotStartButtonTarget.classList.remove('display-none')
    this.hotspotStopButtonTarget.classList.add('display-none')
  }

  popupStart() {
    this.hotspotStop()
    this.editStop()
    this.popupAnchorStop()
    this.dispatch('popup-start', { target: this.editorTarget })

    this.popupStartButtonTarget.classList.add('display-none')
    this.popupStopButtonTarget.classList.remove('display-none')
  }

  popupStop() {
    if (this._contentHasPopups) {
      this.dispatch('popup-stop', { target: this.editorTarget })

      this.popupStartButtonTarget.classList.remove('display-none')
      this.popupStopButtonTarget.classList.add('display-none')
    }
  }

  popupAnchorStart() {
    this.editStop()
    this.hotspotStop()
    this.popupStop()
    this.dispatch('popup-anchor-start', { target: this.editorTarget })

    this.popupStopAnchorButtonTarget.classList.remove('display-none')
    this.popupStartAnchorButtonTarget.classList.add('display-none')
  }

  popupAnchorStop() {
    if (this._contentHasPopups) {
      this.dispatch('popup-anchor-stop', { target: this.editorTarget })

      this.popupStartAnchorButtonTarget.classList.remove('display-none')
      this.popupStopAnchorButtonTarget.classList.add('display-none')
    }
  }

  clearFixupScripts() {
    const fixups = this.editorTarget.contentDocument.querySelector('[name="applied-fixups"]')
    if (!fixups) return

    fixups.content = ''
    this.save()
  }

  save() {
    this.hotspotStop()
    this.editStop()
    this.popupStop()
    this.popupAnchorStop()
    this.dispatch('save', { target: this.editorTarget })

    this.savedIndicatorTarget.textContent = 'Saving...'
  }

  toggleSavedIndicator() {
    this.savedIndicatorTarget.textContent = 'Saved'
    setTimeout(() => { this.savedIndicatorTarget.textContent = '' }, 2000)
  }

  get _contentHasPopups() {
    return this._isHTML && 'mavContainsPopups' in this.editorTarget.contentDocument.body.dataset
  }

  get _isHTML() {
    return this.editorTarget.dataset.editorType === "HTML"
  }
}
