import { Controller as StimulusController } from "@hotwired/stimulus"

/**
 * This loading spinner can be used in two modes.
 *
 * One mode is as a full screen modal spinner which can be activated by passing
 * the data value data-loading-spinner-full-screen-value='true'
 *
 * The other mode will render a spinner in a desired div (normally a button)
 */
export default class LoadingSpinnerController extends StimulusController {
  static targets = ['wrapper']
  static values = { fullScreen: Boolean }

  renderSpinner() {
    this.spinner = this._newSpinner()

    this._spinnerContainer.style.position = 'relative'
    this._spinnerContainer.appendChild(this.spinner)
  }

  removeSpinner() {
    // This might cause problems, but I'm trying not to re-invent this entire behavior
    this._spinnerContainer.style.removeProperty('position')
    this.spinner.remove()
  }

  _newSpinner() {
    const spinner = document.createElement('div')
    spinner.setAttribute('id', 'loading-spinner')
    spinner.classList.add('loading-spinner')
    if (this.fullScreenValue) spinner.classList.add('loading-spinner--lg')
    return spinner
  }

  get _spinnerContainer() {
    if (this.fullScreenValue) return document.querySelector('#modal')

    return this.wrapperTarget
  }
}
