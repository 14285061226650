import ElementData from './element_data'

export default class DefaultElementData extends ElementData {
  details() {
    this._tagName = this.target.tagName.toLowerCase()
    this._elementName = this.titleOrText()

    return this.result
  }
}
