import { Controller as StimulusController } from "@hotwired/stimulus"

export default class ToggleVisibilityController extends StimulusController {
  static targets = [ "toggle", "content" ]

  connect() {
    super.connect()
    if (!this.toggleTarget.checked) this.contentTarget.classList.add('display-none')
  }

  toggle() {
    this.contentTarget.classList.toggle('display-none')
  }
}
