import { Controller as StimulusController } from "@hotwired/stimulus"

/**
 * Stimulus controller that shows warning message if user select non-cached language
 */
export default class SearchWarningController extends StimulusController {
  static targets = ['localesSelect', 'warningMessage']
  static values = {
    orgLocales: Array,
  }

  connect() {
    this._searchAvailable(this.localesSelectTarget.value)
    this.localesSelectTarget.addEventListener('change', (e) =>  this._searchAvailable(e.target.value))
  }

  _searchAvailable(locale) {
    if( locale === 'en' || locale === null || this.orgLocalesValue.includes(locale)) {
      this.warningMessageTarget.classList.add('display-none')
    } else {
      this.warningMessageTarget.classList.remove('display-none')
    }
  }
}
