import StimulusDropdownController from 'stimulus-dropdown'
import { useTransition } from 'stimulus-use'

/**
 * Stimulus controller for showing/hiding a context menu of actions on an item
 */
export default class ContextMenuController extends StimulusDropdownController {
  /**
   * @description Called when the stimulus controller initializes
   * @override Overridden to allow a custom hidden class
   */
  connect() {
    // prevent context menu element from being attached to a controller multiple
    // times during drag/drop scenarios
    if (!this.enter) {
      useTransition(this, {
        element: this.menuTarget,
        hiddenClass: this.hiddenClass,
        transitioned: false
      })
    }
  }

  get hiddenClass() { return 'context-menu__dropdown--closed' }

  /**
   * @param  {Event} event
   * @description Determine when to call the leave action.
   * @override Overridden to allow a custom hidden class
   */
  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains(this.hiddenClass)) {
      this.leave()
    }
  }

  formSubmission(event) {
    if (event.detail.success) {
      this.toggle()
    }
  }
}
