import { Controller as StimulusController } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

/**
 * Stimulus controller for showing/hiding a content drawer with animation
 */
export default class DrawerController extends StimulusController {
  static values = {
    transitioned: {
      type: Boolean,
      default: false,
    },
    resetFooterWidth: {
      type: Boolean,
      default: false,
    },
  }

  /**
   * @description Called when the stimulus controller initializes
   */
  connect() {
    useTransition(this, {
      element: this.element,
      enterActive: 'fade-enter-active',
      enterFrom: 'fade-enter-from',
      enterTo: 'fade-enter-to',
      leaveActive: 'fade-leave-active',
      leaveFrom: 'fade-leave-from',
      leaveTo: 'fade-leave-to',
      hiddenClass: 'collapsed',
      transitioned: this.transitionedValue
    });
  }

  disconnect() {
    if (this.resetFooterWidthValue) {
      this._setFooterWidth('100%')
    }
  }

  close() {
    this.leave()
    this._setFooterWidth('100%')
  }

  open() {
    this.enter()
    this._setFooterWidth('calc(100% - var(--drawer-width))')
  }

  toggle() {
    this.toggleTransition()
  }

  _setFooterWidth(value) {
    document.documentElement.style.setProperty('--footer-width', value);
  }
}
