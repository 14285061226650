import { Controller } from "@hotwired/stimulus"

export default class StepEditorHtmlPopupAnchor extends Controller {
  static targets = ['iframe', 'popupHighligh', 'popupAnchorHighligh']
  _currentPopup = undefined
  _currentAnchor = undefined
  _popups = []

  connect() {
    this._placeHighlight = this.placeHighlight.bind(this)
    this._removeHighlight = this.removeHighlight.bind(this)
    this._placeAnchorHighlight = this.placeAnchorHighlight.bind(this)
    this._selectPopup = this.selectPopup.bind(this)
    this._selectNewAnchor = this.selectNewAnchor.bind(this)
  }

  start() {
    this._popups = this.contentDocument.querySelectorAll('[data-afr-popupid]')

    if (this._popups.length === 0) {
      return
    } else if (this._popups.length === 1) {
      this.setCurrentPopup(this._popups[0])
    } else {
      this.contentDocument.addEventListener('click', this._selectPopup)
      this._popups.forEach(popup => {
        popup.addEventListener('mouseenter', this._placeHighlight)
        popup.addEventListener('mouseleave', this._removeHighlight)
      })
    }
  }

  removePopupSelectListeners() {
    this.contentDocument.removeEventListener('click', this._selectPopup)
    this._popups.forEach(popup => {
      popup.removeEventListener('mouseenter', this._placeHighlight)
      popup.removeEventListener('mouseleave', this._removeHighlight)
    })
  }

  stop() {
    this.removeAnchorListeners()
    this.removePopupSelectListeners()
    this.iframeWindow.removeEventListener('scroll', this._placeCurrentHighlight, true)
    this.iframeWindow.removeEventListener('resize', this._placeCurrentHighlight, true)
    this.removeHighlight()
    this.removeAnchorHighlight()
    this._currentPopup = undefined
    this._currentAnchor = undefined
    this._popups = []
  }

  selectPopup({ target }) {
    const popup = target.closest('[data-afr-popupid]')
    if (popup) { this.setCurrentPopup(popup) }
  }

  setCurrentPopup(popup) {
    this._currentPopup = popup
    this.removePopupSelectListeners()
    this.placeHighlight({ target: popup })
    this.highlighCurrentAnchor()
    this.contentDocument.addEventListener('mouseover', this._placeAnchorHighlight, false)
    this.contentDocument.addEventListener('click', this._selectNewAnchor)

    this._placeCurrentHighlight = this.placeHighlight.bind(this, { target: this._currentPopup })
    this.iframeWindow.addEventListener('scroll', this._placeCurrentHighlight, true)
    this.iframeWindow.addEventListener('resize', this._placeCurrentHighlight, true)
  }

  removeAnchorListeners() {
    this.contentDocument.removeEventListener('mouseover', this._placeAnchorHighlight, false)
    this.contentDocument.removeEventListener('click', this._selectNewAnchor)
  }

  highlighCurrentAnchor() {
    const anchorId = this._currentPopup.dataset.afrPopupid
    if (!anchorId) { return }

    const anchor = this.contentDocument.getElementById(anchorId)
    if (!anchor) { return }

    this._currentAnchor = anchor
    this.placeAnchorHighlight({ target: anchor })
  }

  selectNewAnchor({ target }) {
    if (!target.id) { target.id = `mav-popup-anchor-${Date.now()}` }

    this._currentPopup.dataset.afrPopupid = target.id
    this.highlighCurrentAnchor()
    this.setupScroll()
    this.clearFixupScripts()
    this._currentPopup.style.transform = null

    this.dispatch('update', { prefix: 'anchor' })
  }

  placeHighlight({ target }) {
    this._setHighlight(target, this.popupHighlighTarget)
  }

  placeAnchorHighlight({ target }) {
    this._setHighlight(target, this.popupAnchorHighlighTarget)
  }

  _setHighlight(target, highlightElement) {
    if (!target) return

    const {x, y, width, height} = this.translateCoordinates(target.getBoundingClientRect())
    highlightElement.style.left   = x + 'px'
    highlightElement.style.top    = y + 'px'
    highlightElement.style.width  = width + 'px'
    highlightElement.style.height = height + 'px'

    highlightElement.classList.remove('display-none')
  }

  removeHighlight() {
    this.popupHighlighTarget.classList.add('display-none')
  }

  removeAnchorHighlight() {
    this.popupAnchorHighlighTarget.classList.add('display-none')
  }

  translateCoordinates({x: localX, y: localY, width, height}) {
    const {x: offsetX, y: offsetY} = this.iframeTarget.getBoundingClientRect()
    return({
      x: Math.floor(localX + offsetX),
      y: Math.floor(localY + offsetY),
      width,
      height
    })
  }

  setupScroll() {
    const id = '__FIXUP_STYLESHEET__'
    const oldScript = this.contentDocument.getElementById(id)
    oldScript?.remove()

    const handler = this.mavAnchoredPopUpPositioning.bind(this, this._currentPopup, this._currentAnchor)
    handler()
    this.iframeWindow.addEventListener('scroll', handler, true)
    this.iframeWindow.addEventListener('resize', handler)
  }

  mavAnchoredPopUpPositioning(popup, anchor) {
    const {left, bottom} = anchor.getBoundingClientRect()
    popup.style.left = Math.floor(left)
    popup.style.top  = Math.floor(bottom)
  }

  clearFixupScripts() {
    const fixups = this.contentDocument.querySelector('[name="applied-fixups"')
    if (!fixups) return

    fixups.content = ''
  }

  get contentDocument() {
    return this.iframeTarget.contentDocument
  }

  get iframeWindow() {
    return this.iframeTarget.contentWindow
  }
}
