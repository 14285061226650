import { Controller } from "@hotwired/stimulus"

const inputClass = "password-criteria__input"
const inputClassSuccess = "password-criteria__input--success"

export default class extends Controller {
  static targets = [
    "password",
    "confirmation",
    "submit",
    "characterCount",
    "lowercase",
    "uppercase",
    "symbols",
    "digits",
    "progressBar1",
    "progressBar2",
    "progressBar3",
    "progressText1",
    "progressText2",
    "progressText3",
    "progressText4"
  ]

  connect() {
    this.passwordValid = false
    this.wrapInput(this.passwordTarget)

    if (this.hasConfirmationTarget) {
      this.wrapInput(this.confirmationTarget)
    }
    this.updateButtonClickability()
  }

  validatePassword(e) {
    const password = e.target.value
    this.passwordValid = true
    this.validateField("characterCount", password)
    this.validateField("lowercase", password)
    this.validateField("uppercase", password)
    this.validateField("symbols", password)
    this.validateField("digits", password)
    this.validateField("repeat", password)
    this.showValidation(
      this.passwordTarget.querySelector(`.${inputClass}`),
      this.passwordValid,
      inputClassSuccess
    )

    if (this.hasConfirmationTarget) {
      this.validateConfirmation()
    } else {
      this.updateButtonClickability()
    }
    this.strengthCheck(password);
  }

  validateConfirmation() {
    this.showValidation(
      this.confirmationTarget.querySelector(`.${inputClass}`),
      this.isConfirmationValid() && this.currentPassword.length > 0,
      inputClassSuccess
    )
    this.updateButtonClickability()
  }

  updateButtonClickability() {
    if (!this.hasSubmitTarget) {
      return
    }

    if (this.canSubmit()) {
      this.submitTarget.removeAttribute("disabled")
    } else {
      this.submitTarget.setAttribute("disabled", "disabled")
    }
  }

  validateField(fieldName, password) {
    const capitalField = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)

    if (this[`has${capitalField}Target`]) {
      const valid = this[`valid${capitalField}`](password)
      this.showValidation(this[`${fieldName}Target`], valid, "password-criteria--success")

      if (!valid) {
        this.passwordValid = false
      }
    }
  }

  showValidation(target, valid, className) {
    if (valid) {
      target.classList.add(className)
    } else {
      target.classList.remove(className)
    }
  }

  validCharacterCount(password) {
    return password.length >= this.data.get("min-length")
  }

  validLowercase(password) {
    return (password.match(/[a-z]/g) || []).length >= this.data.get("min-lowercase")
  }

  validUppercase(password) {
    return (password.match(/[A-Z]/g) || []).length >= this.data.get("min-uppercase")
  }

  validSymbols(password) {
    // The ruby validation uses /\p{Punct}|\p{S}/
    const symbols = password.match(/[!"#$%&'()*+,\-./:;<=>?@[\\\]^_‘{|}~]/g) || []
    return symbols.length >= this.data.get("min-symbols")
  }

  validDigits(password) {
    return (password.match(/[0-9]/g) || []).length >= this.data.get("min-digits")
  }

  validRepeat(password) {
    const max = parseInt(this.data.get("max-repeat"))
    let valid = password.length > 0
    for (let char of password) {
      if (password.includes(char.repeat(max + 1))) {
        valid = false
      }
    }
    return valid
  }

  isConfirmationValid() {
    return (
      !this.hasConfirmationTarget || this.currentConfirmation === this.currentPassword
    )
  }

  canSubmit() {
    return (
      this.isConfirmationValid() && this.passwordValid
    )
  }

  wrapInput(target) {
    const wrapper = document.createElement("div")
    wrapper.classList.add(inputClass)

    const input = target.querySelector("input")
    input.parentNode.insertBefore(wrapper, input)
    wrapper.appendChild(input)
  }

  get currentPassword() {
    return this.passwordTarget.querySelector("input").value
  }

  get currentConfirmation() {
    return this.confirmationTarget.querySelector("input").value
  }

  strengthCheck(password) {
    const passwordLength = password.length
    const text1ClassList = this.progressText1Target.classList
    const text2ClassList = this.progressText2Target.classList
    const text3ClassList = this.progressText3Target.classList
    const text4ClassList = this.progressText4Target.classList
    const bar1ClassList = this.progressBar1Target.classList
    const bar2ClassList = this.progressBar2Target.classList
    const bar3ClassList = this.progressBar3Target.classList

    if (passwordLength == 0) {
      text1ClassList.add("active")
      text2ClassList.remove("active")
      text3ClassList.remove("active")
      text4ClassList.remove("active")
      bar1ClassList.remove("active")
      bar2ClassList.remove("active")
      bar3ClassList.remove("active")
    } else if (passwordLength >= 1 && passwordLength < 8) {
      text1ClassList.remove("active")
      text2ClassList.add("active")
      text3ClassList.remove("active")
      text4ClassList.remove("active")
      bar1ClassList.add("active")
      bar2ClassList.remove("active")
      bar3ClassList.remove("active")
    } else if (passwordLength > 7 && passwordLength < 14) {
      text1ClassList.remove("active")
      text2ClassList.remove("active")
      text3ClassList.add("active")
      text4ClassList.remove("active")
      bar2ClassList.add("active")
      bar3ClassList.remove("active")
    } else if (passwordLength >= 14 && this.passwordValid) {
      text1ClassList.remove("active")
      text2ClassList.remove("active")
      text3ClassList.remove("active")
      text4ClassList.add("active")
      bar2ClassList.add("active")
      bar3ClassList.add("active")
    } else if (passwordLength >= 14 && !this.passwordValid) {
      text3ClassList.add("active")
      text4ClassList.remove("active")
      bar2ClassList.add("active")
      bar3ClassList.remove("active")
    }
  }
}