import Edit from "./Edit"

export default class CanvasEdits {
  #collection = []

  constructor(collection) {
    this.#collection = collection
  }

  draw(ctx) {
    this.#collection.forEach(edit => edit.draw(ctx))
  }

  start(x, y, fillStyle) {
    this.#collection.push( new Edit(x, y, fillStyle) )
  }

  trySelect(x, y) {
    let hit = false
    this.#collection.forEach(edit => {
      if (edit.toggleSelection(edit.hitTest(x, y))) {
        hit = true
      }
    })
    return hit
  }

  deselect() {
    this.#collection.forEach(edit => edit.toggleSelection(false))
  }

  toggleSelection() {
    this.#collection.forEach(edit => edit.toggleSelection())
  }

  deleteSelected() {
    this.selected.forEach(edit => {
      this.#collection.splice(this.#collection.indexOf(edit), 1)
    })
  }

  get current() {
    return this.#collection.at(-1)
  }

  get selected() {
    return this.#collection.filter(edit => edit.isSelected)
  }
}