export default class Edit {
  x = 0
  y = 0
  width = 0
  height = 0
  isSelected = false
  textContent = ''
  lineWidth = 2
  fillStyle = 'white'
  textStyle = 'black'
  strokeStyle = 'red'
  textBaseline = 'top'

  constructor(x, y, fillStyle) {
    this.x = x
    this.y = y
    this.fillStyle = fillStyle
  }

  resize(x, y) {
    this.width = x - this.x
    this.height = y - this.y
  }

  toggleSelection(selected = !this.isSelected) {
    this.isSelected = selected
    return selected
  }

  hitTest(x, y) {
    const xDistance = Math.abs(x - this.centerX)
    const yDistance = Math.abs(y - this.centerY)

    return (xDistance < this.width / 2) && (yDistance < this.height / 2)
  }

  draw(ctx) {
    ctx.save()
    ctx.fillStyle = this.fillStyle
    ctx.fillRect(...this.bounds)

    if (this.isSelected) {
      ctx.strokeStyle = this.strokeStyle
      ctx.lineWidth = this.lineWidth
      ctx.strokeRect(...this.bounds)
    }

    ctx.restore()
    this.drawText(ctx)
  }

  drawText(ctx) {
    if (this.height < 12) return

    ctx.save()
    ctx.font = this.font
    ctx.fillStyle = this.textStyle
    ctx.textBaseline = this.textBaseline
    ctx.fillText(this.textContent, this.textSpacing, this.textSpacing)
    ctx.restore()
  }

  get bounds() {
    return [
      this.x,
      this.y,
      this.width,
      this.height
    ]
  }

  get centerX() {
    return this.x + (this.width / 2)
  }

  get centerY() {
    return this.y + (this.height / 2)
  }

  get font() {
    return `bold ${this.height - this.textSpacing}px arial`
  }

  get textSpacing() {
    return Math.floor(this.height / 4)
  }
}