import { Controller as StimulusController } from "@hotwired/stimulus"

/**
 * Stimulus controller to handle selection of an item in a dropdown (select),
 * where each item represents an individual page to which the app can be navigated/directed.
 */
export default class TutorialStepSelect extends StimulusController {
  static targets = ['dropdown']

  optionSelected(event) {
    Turbo.visit(this.urlWithSelectedId(event))
  }

  urlWithSelectedId(event) {
    const deconstructedUrl = this.dropdownTarget.dataset.url.split('/')
    deconstructedUrl[deconstructedUrl.length - 1] = this._selectedId(event)
    return deconstructedUrl.join('/')
  }

  _selectedId(event) {
    if (event.target.dataset.selectedStepId) {
      return event.target.dataset.selectedStepId
    }

    return event.target.closest('.tutorial-practice__step-dropdown-option').dataset.selectedStepId
  }
}
