import { Controller } from "@hotwired/stimulus"

export default class GuideImageWindowController extends Controller {
  static values = {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 860,
    },
    height: {
      type: Number,
      default: 645,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: false,
    }
  }

  connect() {
    this.element.style.width = `${this.widthValue}px`
    this.element.style.height = `${this.heightValue}px`
  }

  setup() {
    window.scrollTo(0, 0) // sometimes the window is affected by InteractiveTutorial's iframe scroll.
    // InteractiveTutorial has finished scrolling, we should now look just like the default guide image
    if (this.isCustomValue) this.element.scrollTo(this.xValue, this.yValue)

    this.readyValue = true
    this.dispatch('ready')
  }

  save() {
    this.xValue = this.element.scrollLeft
    this.yValue = this.element.scrollTop
    this.isCustomValue = true
    this.dispatch('update', { detail: this.#updateDetail })
  }

  reset() {
    this.xValue = 0
    this.yValue = 0
    this.isCustomValue = false
    this.dispatch('update', { detail: this.#updateDetail })
  }

  get #updateDetail() {
    return {
      x: this.xValue,
      y: this.yValue,
      isCustom: this.isCustomValue,
    }
  }
}
