import { Controller as StimulusController } from "@hotwired/stimulus"
import { toggleDisplayNone } from '../helpers/dom_helpers'

export default class BulkLibraryUpdateConfirmController extends StimulusController {
  static targets = [
    'acceptButton',
    'submitButton',
    'modalFooter',
    'message',
    'buttonContainer',
    'libraryCount',
    'checkbox'
  ]

  showConfirm() {
    this.modalFooterTarget.classList.add('large')
    toggleDisplayNone(this.messageTarget)
    this._relocateCloseButton()
    toggleDisplayNone(this.acceptButtonTarget)
    toggleDisplayNone(this.submitButtonTarget)

    this.updateLibraryCount(100)
  }

  updateLibraryCount(delay=3000) {
    setTimeout(() => this.libraryCountTarget.innerHTML = this._numberOfLibraries(), delay)
  }

  /**
   * Move the already initialized close footer button into the proper div so that we control where it renders.
   * It was done this way due to the fact that that button has the required event listener already attached.
   * @private
   */
  _relocateCloseButton() {
    this.buttonContainerTarget.insertAdjacentElement('afterBegin', this.element.querySelector('#modal-close-footer'));
  }

  /**
   * Count the checkmark inputs that have the name "standard_libraries[ids][{id: <>}]" and are checked
   * and get the id from the names and count the unique ones
   * @returns {number}
   * @private
   */
  _numberOfLibraries() {
    const libraryIds = new Set()

    this.checkboxTargets.forEach(input => {
      if (input.checked === true) {
        libraryIds.add(input.dataset.library_id)
      }
    })

    return libraryIds.size
  }
}
