import CaptureController from "./capture_controller.js"

export default class ConnectController extends CaptureController {
  static targets = ["urlInput"]
  static values = {
    tutorialUrl: String,
    tutorialId: Number,
    tutorialName: String
  }

  electronSync() {
    this.dispatch('syncTutorial', { prefix: 'electron', detail: this.#syncDetails })
  }

  get #syncDetails() {
    return {
      tutorial: {
        id: this.tutorialIdValue,
        name: this.tutorialNameValue,
        url: this.tutorialUrlValue,
      },
      tutorialStartUrl: this.urlInputTarget.value
    }
  }
}
