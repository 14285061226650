import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    method: String,
    performOnConnect: Boolean,
  }

  connect() {
    if (this.performOnConnectValue) this.perform()
  }

  perform() {
    const request = new FetchRequest(this.methodValue, this.urlValue, { responseKind: 'turbo-stream' })
    request.perform()
  }
}
