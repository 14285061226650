import InteractiveTutorialStep from './interactive_tutorial_step'

class SelectStep extends InteractiveTutorialStep {
  render() {
    super.render()
    this.focusElement().addEventListener('change', this._onSelect.bind(this))
  }

  handleFocusElementPositionChange(focusElementBoundingBox) {
    this._focusElementBoundingBox = focusElementBoundingBox
    super.handleFocusElementPositionChange()
  }

  _onSelect(_event) {
    const selectElement = this.focusElement()
    const correctOption = this.stepData().option_value.trim().toLowerCase()

    const selectedCorrectOption = selectElement &&
      selectElement.item &&
      selectElement.item(selectElement.selectedIndex).textContent.trim().toLowerCase() === correctOption

    if (selectedCorrectOption) {
      this.onComplete()
    }
  }
}

export default SelectStep
