import SortableLibraryItemsController from './sortable_library_items_controller'
import { patch } from '@rails/request.js'

/**
 * Specialized library items controller for moving library items around within
 * a library
 */
export default class TrainingLibraryController extends SortableLibraryItemsController {
  static values = {
    resourceName: String,
    saveCount: {
      type: Number,
      default: 0,
    }
  }
  static targets = ['draggableItem']

  draggableItemTargetConnected(target) {
    this.createSortableElement(target)
  }

  /**
   * @param  {HTMLNode} {item The element you dragged
   * @param  {Integer} newIndex The index within the to element of where you dropped the dragged element
   * @param  {HTMLNode} to The folder you dropped the dragged element into
   * @param  {HTMLNode} from} The folder the dragged element came from
   * @description Called when you drop a dragged element. Makes ajax request to
   * persist the change on the server
   * @override
   */
  async onEnd({ item, newIndex, from , to}) {
    if (!item.dataset.trainingLibraryItemUpdateUrl) return

    this._updateFolderEmptyStatus(to)
    this._updateCreateSubFolderButton(to)

    this._updateFolderEmptyStatus(from)
    this._updateCreateSubFolderButton(from)

    const body = this._folderMoveFormData(item, newIndex)
    const response = await patch(item.dataset.trainingLibraryItemUpdateUrl, { body })
    if (response.ok) this.saveCountValue += 1
  }

  /**
   * @param  {FormData} formData form data object we are adding parameters to
   * @param  {String} paramName name of the parameter you want to add
   * @param  {String} paramValue value of the parameter you are adding.
   * @description Given a parameter, add it to the form
   */
  _addParamToForm(formData, paramName, paramValue) {
    const resourceName = this.resourceNameValue
    const param = resourceName ? `${resourceName}[${paramName}]` : paramName
    formData.append(param, paramValue)
  }

  _folderMoveFormData(item, newIndex) {
    const data = new FormData()
    this._addParamToForm(data, 'position', newIndex + 1)
    const { parentFolderId } = item.parentElement.dataset
    this._addParamToForm(data, 'parent_id', parentFolderId)
    return data
  }

  // You can set default options in this getter for all sortable elements.
  /**
   * Default options used by SortableJS
   */
  get defaultOptions() {
    return {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: 'library',
      ghostClass: 'is-dragged',
      onMove: this.onMove.bind(this),
      onEnd: this.onEnd.bind(this),
      filter: '.learning-content-card--ignored',
      preventOnFilter: false,
    }
  }
}
