import { Controller as StimulusController } from "@hotwired/stimulus"
// TODO: form for intercepting submit event on "enter key"
export default class StepEditorText extends StimulusController {
  static targets = ["form", "input"]

  show({detail: { bounds: [x, y, width, height] }}) {
    // TODO: display text input at bounds
  }

  submit() {
    // TODO: dispatch text event for edit controller.  text will be owned by Edits..
  }
}