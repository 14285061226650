import { Controller as StimulusController } from "@hotwired/stimulus"
import CanvasImage from "../../models/CanvasImage"

export default class StepEditorImage extends StimulusController {
  static targets = ['canvas', 'image']
  image

  connect() {
    this.image = new CanvasImage(this.imageTarget)
  }

  // canvas elements have a default page size of 300px / 100px w/ matching draw space
  // CSS styles will only affect it's page size, while distorting it's draw space.
  resizeAndDraw() {
    // prevent the canvas from expanding the size of it's container
    this.canvasTarget.width = 0
    this.canvasTarget.height = 0

    // set the canvas elements width & height of the image
    this.canvasTarget.width = this.image.width
    this.canvasTarget.height =  this.image.height

    // The hotspot / edit controllers use these for positioning / context translation
    this.element.dataset.canvasImageOffsetX = this.xTransform
    this.element.dataset.canvasImageOffsetY = this.yTransform

    this.draw()
  }

  beforeSave() {
    // size the canvas to perfectly frame the image for extraction
    this.canvasTarget.width = this.imageTarget.width
    this.canvasTarget.height = this.imageTarget.height
    this.element.dataset.canvasImageOffsetX = 0
    this.element.dataset.canvasImageOffsetY = 0
    this.draw(false)
  }

  draw(notify = true) {
    this.clear()
    this.drawContext.save()
    this.drawContext.translate(this.xTransform, this.yTransform)
    this.image.draw(this.drawContext)
    this.drawContext.restore()
    if (notify) this.dispatch('did-draw')
  }

  clear() {
    this.drawContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
  }

  get drawContext() {
    return this.canvasTarget.getContext('2d')
  }

  get xTransform() {
    return Math.floor((this.canvasTarget.width - this.image.width) / 2)
  }

  get yTransform() {
    return Math.floor((this.canvasTarget.height - this.image.height) / 2)
  }
}
