export function getXPath(el) {
  if (!el) return

  let nodeElem = el;
  let parts = [];

  while (nodeElem && Node.ELEMENT_NODE === nodeElem.nodeType) {
      let nbOfPreviousSiblings = 0;
      let hasNextSiblings = false;
      let sibling = nodeElem.previousSibling;

      if (nodeElem && nodeElem.id) {
        parts.push("/*[@id=\"" + nodeElem.id + "\"]")
        break;
      }

      while (sibling) {
          if (sibling.nodeType !== Node.DOCUMENT_TYPE_NODE &&
              sibling.nodeName === nodeElem.nodeName) {
              nbOfPreviousSiblings++;
          }
          sibling = sibling.previousSibling;
      }
      sibling = nodeElem.nextSibling;

      while (sibling) {
          if (sibling.nodeName === nodeElem.nodeName) {
              hasNextSiblings = true;
              break;
          }
          sibling = sibling.nextSibling;
      }

      const prefix = nodeElem.prefix ? nodeElem.prefix + ":" : "";
      const nth = nbOfPreviousSiblings || hasNextSiblings
          ? "[" + (nbOfPreviousSiblings + 1) + "]"
          : "";
      parts.push(prefix + nodeElem.localName + nth);

      if (nodeElem.localName === 'svg') parts = []

      nodeElem = nodeElem.parentNode;
  }
  return parts.length ? "/" + parts.reverse().join("/") : "";
}

export function getNodeAtXPath(xPathExpression, contextNode = document) {
  const result = (contextNode.ownerDocument || contextNode).evaluate(xPathExpression, contextNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE)

  return result.singleNodeValue
}

export default getXPath
