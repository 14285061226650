import { Controller as StimulusController } from "@hotwired/stimulus"

export default class StepEditorStep extends StimulusController {
  static targets = [
    "xpathInput",
    "elementTagInput",
    "defaultNameValueInput",
    "defaultInputValueInput",
    "xInput",
    "yInput",
    "widthInput",
    "heightInput",
  ]

  update({detail: { xPath, elementTag, defaultNameValue, defaultInputValue, x, y, width, height }}) {
    if (xPath)            this.xpathInputTarget.value = xPath
    if (elementTag)       this.elementTagInputTarget.value = elementTag
    if (x)                this.xInputTarget.value = x
    if (y)                this.yInputTarget.value = y
    if (width)            this.widthInputTarget.value = width
    if (height)           this.heightInputTarget.value = height
    if (defaultNameValue) this.defaultNameValueInputTarget.value = defaultNameValue
    if (defaultInputValue) this.defaultInputValueInputTarget.value = defaultInputValue
  }
}
