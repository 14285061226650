import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['iframe']
  static values = {
    behavior: {
      type: String,
      default: 'smooth'
    },
    hotspotSelector: {
      type: String,
      default: '.tutorial-practice__focus-element'
    },
    frameOnly: Boolean,
  }

  async perform({detail: {hotspotBoundingBox}}) {
    const containerMessage = await this.scrollContainer(hotspotBoundingBox)
    console.log(containerMessage)
    const anchorMessage = await this.scrollHotspotAnchor()
    console.log(anchorMessage)
    const hotspotMessage = await this.scrollHotspot()
    console.log(hotspotMessage)
    this.dispatch('ready')
  }

  scrollContainer(boundingBox) {
    return new Promise((resolve) => {
      const { left, top } = boundingBox
      const { width, height } = this.iframeTarget.getBoundingClientRect()
      const scrollToHorizontal = left - (width / 2) // Scroll to the middle of the screen
      const scrollToVertical = top - (height / 2)

      this.iframeWindow.scrollTo({ top: scrollToVertical, left: scrollToHorizontal, behavior: this.behaviorValue })
      this.afterDelay('container scrolled', resolve)
    })
  }

  scrollHotspot() {
    return new Promise((resolve) => {
      if (!this.hotspot) return resolve('no hotspot')
      if (this.frameOnlyValue) return resolve('hotspot scroll disabled')

      this.hotspot.scrollIntoView({ inline: 'center', block: 'center', behavior: this.behaviorValue } )
      this.afterDelay('hotspot scrolled', resolve)
    })
  }

  scrollHotspotAnchor() {
    return new Promise((resolve) => {
      if (!this.hotspotAnchor) return resolve('no anchor')
      if (this.frameOnlyValue) return resolve('hotspot scroll disabled')

      this.hotspotAnchor.scrollIntoView({ inline: 'center', block: 'center', behavior: this.behaviorValue })
      this.afterDelay('anchor scrolled', resolve)
    })
  }

  afterDelay(message, callback) {
    setTimeout(() => callback(message), this.scrollDelay)
  }

  get hotspot() {
    return this.iframeDocument.querySelector(this.hotspotSelectorValue)
  }

  get hotspotAnchor() {
    // If the focus element is located inside of a popup, scrolling the focus element into view is not enough.
    // We also need to scroll it's anchor into view, unless the popup is enormous.
    if (!this.hotspotPopup || 1000 < this.hotspotPopup.getBoundingClientRect().width) return

    return this.iframeDocument.getElementById(this.hotspotPopup.dataset.afrPopupid)
  }

  get hotspotPopup() {
    return this.hotspot?.closest('[data-afr-popupid]')
  }

  get iframeWindow() {
    return this.iframeTarget.contentWindow
  }

  get iframeDocument() {
    return this.iframeTarget.contentDocument
  }

  // TODO: Revisit when scrollend is widely supported.
  // We've adjusted this delay a few times, but it'll never be perfect for all steps.
  get scrollDelay() {
    return this.behaviorValue === 'smooth' ? 900 : 30
  }
}
