import CaptureController from "./capture_controller.js"
import { FetchRequest } from "@rails/request.js"

export default class PersistController extends CaptureController {
  static targets = ['form']

  async process() {
    const request = new FetchRequest(this.method, this.URL, { body: this.body })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      this.render(body)
    } else {
      console.log(response)
    }
  }

  render(body) {
    this.element.innerHTML = body
  }

  get method() {
    return this.formTarget.method
  }

  get URL() {
    return this.formTarget.action
  }

  get body() {
    return new FormData(this.formTarget)
  }
}
