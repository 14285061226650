import { Controller as StimulusController } from "@hotwired/stimulus"

export default class StepEditorHtmlEdit extends StimulusController {
  static targets = ['iframe']

  start() {
    this.iframeBody.setAttribute('contenteditable', true)
  }

  stop() {
    this.iframeBody.removeAttribute('contenteditable')
  }

  get iframeDocument() {
    return this.iframeTarget.contentDocument
  }

  get iframeBody() {
    return this.iframeDocument.body
  }
}
