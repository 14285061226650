import { Controller as StimulusController } from "@hotwired/stimulus"
import RolemodelModal from '../../helpers/rolemodel-modal'
import Trix from 'trix'

// This assumes that the attachment is being edited in a modal.
// We could extract that part later if there is a different pattern that emerges
export default class AttachmentRefreshController extends StimulusController {
  static values = {
    trixAttachmentSgid: String,
    attachmentData: Object
  }

  connect() {
    const attachmentElement = document.querySelector(`[data-trix-attachment*="${this.trixAttachmentSgidValue}"]`)
    const trixAttachmentId = parseInt(attachmentElement.dataset.trixId)
    const editor = attachmentElement.closest('trix-editor').editor
    const attachment = editor.getDocument().getAttachmentById(trixAttachmentId)
    editor.composition.removeAttachment(attachment)

    const newAttachment = new Trix.Attachment(this.attachmentDataValue);
    editor.insertAttachment(newAttachment);

    // close the modal
    document.getElementById('close-modal').click()

    // remove the controller
    this.element.remove()
  }
}
