import ElementData from './element_data'

export default class SelectElementData extends ElementData {
  details() {
    this._tagName = 'select'

    const labelText = this.findLabelText()
    if (labelText) {
      this._elementName = labelText
    } else if (this.hasAttributeWithValue('name')) {
      this._elementName = this.target.getAttribute('name');
    }

    return this.result
  }
}
