import { setElementDimensions, setElementLocation } from '../../../helpers/dom_helpers'

class InteractiveTutorialStep {
  /**
   * Baseclass for all tutorial step types
   * @param parentElement the dom element that the stimulus controller was attached to (also the parent element of the iframe)
   * @param focusElement the next element to click inside the iframe
   * @param stepData a json blob with step data
   * @param onCompleteFunction function to call on step completion
   * @param focusElementBoundingBox the element
   */
  constructor(parentElement, focusElement, stepData, onCompleteFunction, focusElementBoundingBox) {
    this._parentElement = parentElement
    this._focusElement = focusElement
    this._stepData = stepData
    this.onComplete = onCompleteFunction
    this._focusElementBoundingBox = focusElementBoundingBox
  }

  parentElement() {
    return this._parentElement
  }

  focusElement() {
    return this._focusElement
  }

  stepData() {
    return this._stepData
  }

  nextStepUrl() {
    return this._nextStepUrl
  }

  focusElementBoundingBox() {
    return this._focusElementBoundingBox
  }

  /**
   * This is called when the stimulus controller mounts and should do any logic that is required to
   * correctly display the step in the dom
   */
  render() {
    this.initializeFocusElementHighlight()
  }

  /**
   * This function will be called by the stimulus controller to on scroll or resize
   */
  handleFocusElementPositionChange() {
    this._updateFocusElementHighlightSizeAndPosition()
  }

  /**
   * If the content stored is a PNG, metadata will be passed through in the step defining the click target
   * @returns {boolean} True if the content attachment on an image
   */
  contentIsImage() {
    return this.stepData().action_width !== undefined
  }

  /**
   * Creates a *non-clickable* div with a green border that gets placed exactly overtop the focus element in the iframe.
   * This element has a large translucent box shadow that darkens the rest of the screen slightly.
   */
  initializeFocusElementHighlight() {
    this._focusElementHighlight = this.parentElement().appendChild(document.createElement('div'))
    this._focusElementHighlight.className = 'tutorial-practice__focus-element-highlight'
    this._focusElementHighlight.dataset.interactiveTutorialTarget = 'highlightElement'
    this._updateFocusElementHighlightSizeAndPosition()
  }

  /**
   * This function will position the focus element highlight element to be exactly on top of the focus element
   */
  _updateFocusElementHighlightSizeAndPosition() {
    let { top, left, width, height }  = this.focusElementBoundingBox()
    setElementLocation(this._focusElementHighlight, left, top)
    setElementDimensions(this._focusElementHighlight, width, height)
  }
}

export default InteractiveTutorialStep

