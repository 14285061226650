import { createElement, numberFromPixels, numberToPixels, setElementLocation } from '../../helpers/dom_helpers'

class DraggableModal {
  constructor(rootElement, positionOnGrid, gridSquareNumber) {
    this._rootElement = rootElement
    this._positionOnGrid = positionOnGrid
    this._gridSquareNumber = gridSquareNumber

    this._createElement()
  }

  rootElement() { return this._rootElement }
  positionOnGrid() { return this._positionOnGrid }
  gridSquareNumber() { return this._gridSquareNumber }
  element() { return this._element }

  _createElement() {
    this._modalDragBox = createElement(this.rootElement(), 'div', 'tutorial-practice__modal-drag-box')
    this._element = createElement(this.rootElement(), 'div', 'tutorial-practice__modal')
    let modalWidth = 500
    this.element().style.width = numberToPixels(modalWidth)

    // The modal drag box is an invisible box that sits behind the modal. If the mouse is moved quickly,
    // it will move faster than the modal. The modal drag box will capture the move events in that case.
    this._modalDragBox.style.width = numberToPixels(modalWidth + 200)
    this._modalDragBox.style.height = numberToPixels(400)

    this.element().addEventListener('mousedown', () => this._modalMouseDown = true)
    this.element().addEventListener('mousemove', this._modalMouseMove.bind(this))
    this._modalDragBox.addEventListener('mousemove', this._modalMouseMove.bind(this))
    this.element().addEventListener('mouseup', this._endDrag.bind(this))
  }

  updatePosition(focusElementBoundingBox) {
    const { modalLeft, modalTop, modalRight } = this._getModalPosition(focusElementBoundingBox)
    const absoluteLeft = Math.max(10, modalLeft)
    const absoluteTop = Math.max(10, modalTop)
    setElementLocation(this.element(), absoluteLeft, absoluteTop, modalRight)
    this._updateModalDragBoxPosition(absoluteLeft, absoluteTop)
  }

  /**
   * This allows the user to drag the modal on the screen and reposition it.
   * @param event
   * @private
   */
  _modalMouseMove({ pageX, pageY, buttons }) {
    if (buttons !== 1 || !this._modalMouseDown) {
      return this._endDrag()
    }

    const diffX = pageX - this._lastX
    const diffY = pageY - this._lastY
    this._lastX = pageX
    this._lastY = pageY
    const { left, top, right } = this.element().style
    const { width } = this.element().getBoundingClientRect()

    let modalLeft
    if (right) {
      modalLeft = this._iframeElement().width - numberFromPixels(right) - width
    } else {
      modalLeft = numberFromPixels(left)
    }

    const modalTop = numberFromPixels(top)
    const newLeft = modalLeft + diffX
    const newTop = modalTop + diffY

    if (this._attemptingToDragOutOfIframe(newLeft, newTop)) {
      return
    }

    this._userHasMovedModal = true

    setElementLocation(this.element(), newLeft, newTop)
    this._updateModalDragBoxPosition(newLeft, newTop)
  }

  _attemptingToDragOutOfIframe(newLeft, newTop) {
    const newRight = newLeft + this.element().getBoundingClientRect().width
    const newBottom = newTop + this.element().getBoundingClientRect().height
    const { clientWidth: maxWidth, clientHeight: maxHeight } = this.rootElement()

    return newRight > maxWidth ||
      newBottom > maxHeight ||
      newLeft < 0 ||
      newTop < 0
  }

  _endDrag() {
    this._modalMouseDown = false
    this._lastX = undefined
    this._lastY = undefined
  }

  _updateModalDragBoxPosition(left, top) {
    const { width: modalWidth, height: modalHeight } = this.element().getBoundingClientRect()
    const { width, height } = this._modalDragBox.getBoundingClientRect()
    const adjustedWidth = (width - modalWidth) / 2
    const adjustedHeight = (height - modalHeight) / 2
    setElementLocation(this._modalDragBox, left - adjustedWidth, top - adjustedHeight)
  }

  _getModalPosition(focusElementBoundingBox) {
    if (this._shouldPositionOnGrid()) {
      return this._getModalGridPosition()
    }

    const {
      left: focusElementLeft,
      bottomAvailableSpace: focusElementBottomAvailableSpace,
      top: focusElementTop,
      height: focusElementHeight,
    } = focusElementBoundingBox
    const { height: modalHeight, width: modalWidth } = this.element().getBoundingClientRect()
    const margin = 28
    let modalTop = focusElementTop + focusElementHeight + margin
    let modalLeft = focusElementLeft
    const modalRight = modalLeft + modalWidth
    const notEnoughRoomBelowFocusElement = focusElementBottomAvailableSpace < (modalHeight + 2 * margin)
    const notEnoughRoomAboveFocusElement = (top - modalHeight - margin) < 0
    const modalRightIsOffScreen = modalRight > this._iframeElement().width

    if (notEnoughRoomBelowFocusElement) {
      modalTop = focusElementTop - modalHeight - margin
    }

    if (notEnoughRoomBelowFocusElement && notEnoughRoomAboveFocusElement) {
      // position in the middle of the focus element
      modalTop = (modalHeight / 2) - (margin / 2)
    }

    if (modalRightIsOffScreen) {
      const difference = modalRight - this._iframeElement().width
      modalLeft = focusElementLeft - (difference + margin)
    }
    return { modalLeft, modalTop }
  }

  /**
   * Some steps override the position of the modal that is rendered. The property 'cb' (gridSquareNumber)
   * is used to communicate which grid square in a grid of 9 squares the modal should position itself in
   * with zero being none.
   * @returns {boolean}
   * @private
   */
  _shouldPositionOnGrid() {
    return this._positionOnGrid === true && this.gridSquareNumber() !== 0
  }

  /**
   * This function returns a modalLeft (or modalRight) and a modalTop that inform where the modal
   * should be positioned in a grid of 1-9 (see below)
   * | 1 | 2 | 3 |
   * | 4 | 5 | 6 |
   * | 7 | 8 | 9 |
   * @returns {{modalLeft: (string|number), modalTop: string, modalRight: string}}
   * @private
   */
  _getModalGridPosition() {
    const modalLeft = this._getModalLeftForGrid()
    const modalTop = this._getModalTopForGrid()
    const modalRight = this._getModalRightForGrid()
    return { modalLeft, modalTop, modalRight }
  }

  /**
   * @returns {number} A pixel value indicating how far from the left side of the iframe to position
   */
  _getModalLeftForGrid() {
    const width = this.element().getBoundingClientRect().width
    const middle = (this._iframeElement().width / 2) - (width / 2)

    if ([1, 4, 7].includes(this.gridSquareNumber())) { return 15 }
    if ([2, 5, 8].includes(this.gridSquareNumber())) { return middle }
  }

  /**
   * @returns {number} A pixel value indicating how far from the right side of the iframe to position
   */
  _getModalRightForGrid() {
    if ([3, 6, 9].includes(this.gridSquareNumber())) { return 15 }
  }

  /**
   * @returns {number} A pixel value indicating how far from the top of the iframe to position
   */
  _getModalTopForGrid() {
    const height = this.element().getBoundingClientRect().height
    const middleOfTopRow = (this._iframeElement().height * 0.15) - (height / 2)
    const middleOfMiddleRow = (this._iframeElement().height * 0.5) - (height / 2)
    const middleOfBottomRow = (this._iframeElement().height * 0.85) - (height / 2)

    if ([1, 2, 3].includes(this.gridSquareNumber())) { return middleOfTopRow }
    if ([4, 5, 6].includes(this.gridSquareNumber())) { return middleOfMiddleRow }
    return middleOfBottomRow
  }

  _iframeElement() {
    return this.rootElement().querySelector('iframe').getBoundingClientRect()
  }
}

export default DraggableModal
