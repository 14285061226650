import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']
  static classes = ['active']

  scrollIntoView() {
    // allow time for itemTargets to connect before scrolling.
    setTimeout(()=> this.#currentlyActiveItem?.scrollIntoView({behavior: 'smooth', block: 'center'}), 100)
    // Smooth scrolling is utilitarian in this case, indicating where the item you just clicked has moved to.
  }

  itemTargetConnected(item) {
    if (item.dataset.itemId === this.#activeItemId) {
      item.classList.add(this.activeClass)
    }
  }

  // `currentTarget` refers to the element with the `data-action`
  //  regardless of where the event originated (the event's `target`)
  select({currentTarget}) {
    this.itemTargets.forEach(item => {
      if (item.dataset.itemId === currentTarget.dataset.itemId) {
        item.classList.add(this.activeClass)
      } else {
        item.classList.remove(this.activeClass)
      }
    })
  }

  // we need to pull the ID directly from the url, rather than from a **value** because the sidebar
  // does not re-render when the current step changes. Which is one of the reasons this value is
  // quite difficult to cache. The other being that the controller's `connect` function tends to run
  // before its itemTarget's are all connected, since they are rendered via src'ed turbo-frame.
  get #activeItemId() {
    return window.location.pathname.split('/').reverse().find(segment => /^\d+$/.test(segment))
  }

  get #currentlyActiveItem() {
    return this.itemTargets.find(item => item.classList.contains(this.activeClass))
  }
}
