'use strict';

if (process.env.RAILS_ENV === "production") {
  if (!document.querySelector("[data-apty]")) {
    const contentURL = "https://client.app.apty.io";
    const element = window.document.createElement ( "script" );
    const tenantKey = "jJ4cC2uP";
    const timeStamp =  new Date().getTime();
    element.setAttribute("src", contentURL + "/tenant-" + tenantKey + "/injected-hook.js?timestamp=" + timeStamp + "&token=");
    window.document.head.appendChild(element);
  }
}
