import { Controller as StimulusController } from "@hotwired/stimulus"
import csrfToken from '../lib/csrfToken'
import { createElement } from '../helpers/dom_helpers'

export default class DisabledUntilRequestButton extends StimulusController {
  connect() {
    this.updateButtonWhenDataFetched()
  }

  async updateButtonWhenDataFetched() {
    var buttonElement = this.element
    var dataToCheck = buttonElement.getAttribute("href")

    buttonElement.classList.add('position-relative')
    const loadingSpinner = createElement(buttonElement, 'span', 'loading-spinner')

    const response = await this.fetchData(dataToCheck)
    const enableButton = await response.json()

    buttonElement.removeChild(loadingSpinner)

    if (enableButton) {
      buttonElement.classList.remove('disabled')
    }
  }

  async fetchData(data) {
    return fetch(
      data,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        }
      }
    )
  }
}
