import { Controller as StimulusController } from "@hotwired/stimulus"

export default class extends StimulusController {
  connect() {
    this.setup()
  }

  setup() {
    const toolbar = this.element.toolbarElement

    if (toolbar.querySelector("[data-trix-attribute='heading2']")) { return }

    const h2ButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2">H2</button>'
    const h3ButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3">H3</button>'

    const heading1 = toolbar.querySelector("[data-trix-attribute='heading1']")
    heading1.insertAdjacentHTML("afterend", h2ButtonHTML)
    heading1.textContent = 'H1'
    heading1.classList.remove('trix-button--icon')

    const heading2 = toolbar.querySelector("[data-trix-attribute='heading2']")
    heading2.insertAdjacentHTML("afterend", h3ButtonHTML)
  }
}
