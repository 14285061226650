export default class ElementData {
  constructor(target, iframeDocument) {
    this._target = target
    this._iframeDocument = iframeDocument
  }

  details() {
    throw new Error('Subclass should override')
  }

  findLabelText() {
    let labelElement = this.iframeDocument.querySelector(`[for="${this.target.id}"]`)
    if (labelElement) {
      return labelElement.textContent
    }
  }

  hasAttributeWithValue(name) {
    return this.target.hasAttribute(name) && !!this.target.getAttribute(name).trim()
  }

  titleOrText() {
    if (this.hasAttributeWithValue('title')) {
      return this.target.getAttribute('title')
    } else {
      return this.truncate(this.target.textContent, 300)
    }
  }

  get result() {
    return { elementTag: this.tagName, defaultNameValue: this.elementName, defaultInputValue: this.elementInput }
  }

  get elementName() {
    return this.elementNameIsSet ? this._elementName : 'none'
  }

  get elementNameIsSet() {
    return this._elementName && !!this._elementName.trim()
  }

  get elementInput() {
    return this._elementInput
  }

  get tagName() {
    return this._tagName
  }

  get target() {
    return this._target
  }

  get iframeDocument() {
    return this._iframeDocument
  }

  truncate(value, num) {
    if (!value || value.length <= num) { return value }

    return value.slice(0, num)
  }
}
