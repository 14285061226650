import StimulusCheckboxSelectAll from "stimulus-checkbox-select-all"
import csrfToken from '../lib/csrfToken'

export default class MultiselectController extends StimulusCheckboxSelectAll {

  static targets = ['summary', 'tray', 'checkbox', 'checkboxAll']
  static values = {
    url: String,
    count: Number,
    type: String
  }

  // toggle the check-all checkbox
  toggle(event) {
    event.target.checked ?
      this.selectAll() :
      this.deselectAll()
  }

  selectAll() {
    this.checkboxTargets.forEach(input => input.checked = true)
    this.refresh()
    return this.updateServer({ select_all: true })
      .then(response => response.json())
      .then(json => {
        this.countValue = json.count
        this.updateSummary()
      })
  }

  toggleCheckbox(event) {
    const operation = {}
    if (event.target.checked) {
      this.countValue = this.countValue + 1
      operation['add'] = event.target.value
    } else {
      this.countValue = this.countValue - 1
      operation['remove'] = event.target.value
    }

    return this.updateServer(operation).then(() => this.updateSummary())
  }

  deselectAll() {
    return fetch(
      this.urlValue,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({})
      }
    ).then(() => {
      this.countValue = 0
      this.checkboxAllTarget.checked = false
      this.checkboxTargets.forEach(input => input.checked = false )
      this.refresh()
      this.updateSummary()
    })
  }

  updateServer(operation) {
    return fetch(
      this.urlValue,
      {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({ multiselect: operation })
      }
    )
  }

  updateSummary() {
    const r = /\d+/
    this.summaryTarget.textContent = this.summaryTarget.textContent.replace(r, this.countValue)
    if (this.countValue === 0) {
      this.trayTarget.classList.add('hidden')
    } else {
      this.trayTarget.classList.remove('hidden')
    }
  }
}
