import Sortable from 'stimulus-sortable'
import { patch } from '@rails/request.js'

// This controller expects it's element to be a turbo-frame
export default class SortableFrameController extends Sortable {
  async update ({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return

    const body = new FormData()
    body.append(this.paramName, newIndex + 1)

    const response = await patch(item.dataset.sortableUpdateUrl, { body })
    if (response.ok) this.element.reload()
  }

  get options() {
    return {
      animation: 150,
      handle: undefined,
      onUpdate: this.update.bind(this)
    }
  }

  get paramName() {
    return this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue
  }
}
