import ElementData from './element_data'

export default class TdElementData extends ElementData {
  details() {
    this._tagName = 'td'
    const table = this.target.closest("table");

    if (table && table.hasAttribute("summary")) {
      this._elementName = table.getAttribute("summary");
    }

    return this.result
  }
}
