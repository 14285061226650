import { Controller as StimulusController } from "@hotwired/stimulus"
import CanvasEdits from "../../models/CanvasEdits"

export default class StepEditorImageEdit extends StimulusController {
  static targets = ['canvas', 'image']
  static values = {
    listening: {
      type: Boolean,
      default: false,
    },
    capturing: {
      type: Boolean,
      default: false,
    },
    typing: {
      type: Boolean,
      default: false,
    }
  }
  edits

  connect() {
    this.edits = new CanvasEdits([])
  }

  draw() {
    this.drawContext.save()
    this.drawContext.translate(this.xTransform, this.yTransform)
    this.edits.draw(this.drawContext)
    this.drawContext.restore()
  }

  async flatten() {
    const imageBlob = await this.canvasToImage()
    await this.loadImageURL(URL.createObjectURL(imageBlob))
    this.edits = new CanvasEdits([])
  }

  captureStart(event) {
    if (!this.listeningValue) return
    // the drawing context is always translated before drawing
    const xDestination = Math.floor(event.offsetX - this.xTransform)
    const yDestination = Math.floor(event.offsetY - this.yTransform)

    // see if this is a 'click' on an existing edit, otherwise start a new edit
    if (!this.edits.trySelect(xDestination, yDestination)) {
      this.capturingValue = true

      const fillStyle = this.pixelColor(event.offsetX, event.offsetY)
      this.edits.start(xDestination, yDestination, fillStyle)
    }
    this.dispatch('redraw')
  }

  captureSize(event) {
    if (!this.listeningValue) return
    if (!this.capturingValue) return

    const xDestination = Math.floor(event.offsetX - this.xTransform)
    const yDestination = Math.floor(event.offsetY - this.yTransform)

    this.edits.current.resize(xDestination, yDestination)
    this.dispatch('redraw')
  }

  captureStop() {
    if (!this.listeningValue) return
    if (!this.capturingValue) return

    this.capturingValue = false
  }

  hotkey(event) {
    if (!this.listeningValue) return
    if (this.capturingValue) return
    if (this.typingValue) return

    if (event.key === 'Backspace' && event.ctrlKey) {
      this.edits.deleteSelected()
      this.dispatch('redraw')

    } else if (event.key === 'a' && event.ctrlKey) {
      this.edits.toggleSelection()
      this.dispatch('redraw')

    } else if (event.key === 't' && event.ctrlKey) {
      if (1 < this.edits.selected.length) return
      // TODO: TextController not implemented yet
      // this.typingValue = true
      // this.dispatch('text-input', {detail: {bounds: this.edits.selected[0].bounds}})
    }
  }

  editStart() {
    this.listeningValue = true
    this.capturingValue = false
  }

  editStop() {
    this.listeningValue = false
    this.capturingValue = false

    this.edits.deselect()
    this.dispatch('redraw')
  }

  pixelColor(x, y) {
    const [red, green, blue, alpha] = this.drawContext.getImageData(x, y, 1, 1).data
    return `rgba(${red}, ${green}, ${blue}, ${alpha / 255})`
  }

  canvasToImage() {
    return new Promise(resolve => this.canvasTarget.toBlob(blob => resolve(blob)))
  }

  loadImageURL(url) {
    this.imageTarget.src = url
    return new Promise(resolve => {
      this.imageTarget.onload = () => {
        URL.revokeObjectURL(url)
        resolve()
      }
    })
  }

  get drawContext() {
    return this.canvasTarget.getContext('2d')
  }

  get xTransform() {
    return this.element.dataset.canvasImageOffsetX
  }

  get yTransform() {
    return this.element.dataset.canvasImageOffsetY
  }
}