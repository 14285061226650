import { Controller } from "@hotwired/stimulus"
import Tippy from 'tippy.js'

export default class PopupController extends Controller {
  static targets = ['trigger', 'content']
  static values = {
    action: String,
    scrollable: Boolean,
    scrollToId: String
  }

  initialize() {
    this.initPopup()
  }

  initPopup() {
    Tippy(this.getTrigger(), {
      content: this.getContent().innerHTML,
      allowHTML: true,
      maxWidth: 800,
      theme: 'light',
      appendTo: this.element,
      trigger: this.actionValue || 'mouseenter',
      onShown: this.onShown.bind(this),
      ...this._scrollableProps()
    })
  }

  _scrollableProps() {
    if (this.scrollableValue) {
      return {
        interactive: true,
        popperOptions: {
          modifiers: {
            preventOverflow: { enabled: false },
            hide: { enabled: false }
          }
        }
      }
    }
  }

  /**
   * Scroll to a certain element within the popup after it opens. Tippy duplicates the content
   * of the contentTarget into a separate div when it renders the popup, so in order to interact
   * with it the second one must be found
   */
  onShown() {
    if (!this.scrollableValue || !this.scrollToIdValue) {
      return
    }

    const selectedRow = document.querySelectorAll(this.scrollToIdValue)[1]
    const popupDiv = document.querySelectorAll('.popup')[1]
    popupDiv.scrollTo({ top: selectedRow.getBoundingClientRect().top - 200, behavior: 'smooth' })
  }

  getContent() {
    if (this.hasContentTarget) {
      return this.contentTarget
    } else {
      const content = document.createElement('div')
      content.innerHTML = this.data.get('content')
      return content
    }
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget
    } else {
      return this.element
    }
  }
}
