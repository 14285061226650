import StimulusCheckboxSelectAll from "stimulus-checkbox-select-all"

export default class SelectableLearningModulesController extends StimulusCheckboxSelectAll {
  connect() {
    super.connect()
    this._checkAll()
  }

  toggle(event) {
    super.toggle(event)

    this.checkboxTargets.forEach(checkbox => {
      if (event.target.checked) {
        if (!checkbox.checked) { return }
        return this._addSelectedClass(checkbox)
      } else {
        this._removeSelectedClass(checkbox)
      }
    })
  }

  onClickLearningModule(event) {
    const checkbox = this._learningModuleCheckbox(event.target)
    checkbox.checked = !checkbox.checked
    this._toggleSelectedClass(event.target)
    this.refresh()
  }

  onClickLearningModuleCheckbox(event) {
    this._toggleSelectedClass(event.target)
  }

  refresh () {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount === checkboxesCount
  }

  _checkAll() {
    this.checkboxAllTarget.checked = true
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
      this._addSelectedClass(checkbox)
      this.triggerInputEvent(checkbox)
    })
  }

  _addSelectedClass(checkbox) {
    this._learningModuleCard(checkbox).classList.add(this._selectedClass())
  }

  _removeSelectedClass(checkbox) {
    this._learningModuleCard(checkbox).classList.remove(this._selectedClass())
  }

  _toggleSelectedClass(target) {
    if (this._learningModuleCard(target).classList.contains(this._selectedClass())) {
      this._removeSelectedClass(target)
    } else {
      this._addSelectedClass(target)
    }
  }

  _learningModuleCard(target) {
    return target.closest('.learning-content-card')
  }

  _learningModuleCheckbox(target){
    return this._learningModuleCard(target).querySelector('input[type=checkbox]')
  }

  _selectedClass() {
    return 'selected'
  }
}
