import { Controller as StimulusController } from "@hotwired/stimulus"


/**
 * Stimulus controller that focuses and selects text in an HTML input
 */
export default class InputFocusController extends StimulusController {
  connect() {
    this.element.focus()
    this.element.select()
  }
}
