import TextInputStep from './step_types/text_input_step'
import ViewStep from './step_types/view_step'
import ClearTextInputStep from './step_types/clear_text_input_step'
import ClickActionStep from './step_types/click_action_step'
import SelectStep from './step_types/select_step'
import CloseStep from './step_types/close_step'

class TutorialStepFactory {
  constructor(element, focusElement, stepData, onCompleteCallback, focusElementBoundingBox) {
    this._element = element
    this._focusElement = focusElement
    this._stepData = stepData
    this._onCompleteCallback = onCompleteCallback
    this._focusElementBoundingBox = focusElementBoundingBox
    this._fixupElementTag()
  }

  get stepData() {
    return this._stepData
  }

  makeStep(overrideKlass = undefined) {
    let klass = ClickActionStep
    switch (this.stepData.elementTag) {
      case 'input-text':
      case 'input-password':
        if (this.stepData.type === 'clear') {
          klass = ClearTextInputStep
        } else {
          klass = TextInputStep
        }
        break
      case 'select':
        klass = SelectStep
        break
    }

    if (overrideKlass) {
      klass = overrideKlass
    } else if (this.stepData.type === 'custom' && this.stepData.customView) {
      klass = ViewStep
    } else if (this.stepData.type === 'view') {
      klass = ViewStep
    } else if (this.stepData.type === 'close') {
      klass = CloseStep
    }

    return new klass(this._element, this._focusElement, this._stepData, this._onCompleteCallback, this._focusElementBoundingBox)
  }

  _fixupElementTag() {
    if (this.stepData.type === 'input' && !this.stepData.elementTag) {
      this._stepData.elementTag = 'input-text'
    }
  }
}

export default TutorialStepFactory
