import { Controller } from "@hotwired/stimulus"

export default class StepEditorHtmlPopup extends Controller {
  static targets = ['iframe']
  _targetPopup = undefined
  _initialOffset = {x: 0, y: 0}

  connect() {
    this._enableDragging = this._enableDragging.bind(this)
    this._dragPopup = this._dragPopup.bind(this)
    this._disableDragging = this._disableDragging.bind(this)
  }

  start() {
    this._contentWindow.addEventListener('pointerdown', this._enableDragging)
  }

  stop() {
    this._contentWindow.removeEventListener('pointerdown', this._enableDragging)
    this._disableDragging()
  }

  _enableDragging({target, x, y}) {
    const popup = target.closest('[data-afr-popupid]')
    if (popup) {
      this._targetPopup = popup
      this._initialOffset = this._pointerOffset(x, y)
      this._contentWindow.addEventListener('pointermove', this._dragPopup)
      this._contentWindow.addEventListener('pointerup', this._disableDragging)
    }
  }

  _disableDragging() {
    this._contentWindow.removeEventListener('pointermove', this._dragPopup)
    this._contentWindow.removeEventListener('pointerup', this._disableDragging)
    this._targetPopup = undefined
    this._initialOffset = {x: 0, y: 0}
  }

  _dragPopup({x: pointerX, y: pointerY}) {
    if (this._targetPopup === undefined) return

    const { x, y } = this._pointerOffset(pointerX, pointerY)
    const xTransform = x - this._initialOffset.x + this._currentXTransform
    const yTransform = y - this._initialOffset.y + this._currentYTransform
    const newTransform = `translate(${Math.floor(xTransform - this._contentWindow.scrollX)}px, ${Math.floor(yTransform - this._contentWindow.scrollY)}px)`

    this._targetPopup.dataset.xTransform = Math.floor(xTransform)
    this._targetPopup.dataset.yTransform = Math.floor(yTransform)
    this._targetPopup.style.setProperty('transform', newTransform)
    this.dispatch('update', { prefix: 'popup' })
  }

  _pointerOffset(pointerX, pointerY) {
    const { top, left } = this._targetPopup.getBoundingClientRect()
    const x = pointerX - left
    const y = pointerY - top
    return {x, y}
  }

  get _currentXTransform() {
    return parseFloat(this._targetPopup.dataset.xTransform || '0.0')
  }

  get _currentYTransform() {
    return parseFloat(this._targetPopup.dataset.yTransform || '0.0')
  }

  get _contentWindow() {
    return this.iframeTarget.contentWindow
  }
}
