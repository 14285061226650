export default class CanvasImage {
  #imageElement

  constructor(el) {
    this.#imageElement = el
  }

  draw(ctx) {
    ctx.drawImage(this.#imageElement, 0, 0)
  }

  get width() {
    return this.#imageElement.width
  }

  get height() {
    return this.#imageElement.height
  }
}