import { Controller as StimulusController } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

export default class extends StimulusController {
  static values = {
    url: String,
  }

  async updateCell(event) {
    await patch(this.urlValue, {
      body: { value: event.target.innerHTML },
      query: {
        operation: "update_cell",
        row_index: event.target.dataset.rowIndex,
        column_index: event.target.dataset.columnIndex,
      },
      contentType: "application/json",
    })
  }
}
