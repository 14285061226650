import TextInputStep from './text_input_step'

class ClearTextInputStep extends TextInputStep {
  /**
   * Override the render input function to populate it with the a value that must be cleared.
   * The value comes straight from the focus element in the oracle html.
   * @private
   */
  _renderInput() {
    super._renderInput()
    this._input.value = this.focusElement().value
  }

  _correctValue() {
    return ''
  }
}

export default ClearTextInputStep
