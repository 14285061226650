import { Controller as StimulusController } from "@hotwired/stimulus"

export default class ScrollIntoViewController extends StimulusController {
  scroll() {
    const scrollId = this.data.get('id');

    const elementToScrollTo = document.getElementById(scrollId);

    // content will scroll out of viewport becuase of upward scrolling
    // use block: 'nearest' to stop upward scrolling in most cases
    elementToScrollTo.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })
    elementToScrollTo.focus();
  }
}
