import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Third-party Stimulus controller registrations go here
import Sortable from 'stimulus-sortable'
application.register('sortable', Sortable)

application.debug = process.env.NODE_ENV === 'development'
window.Stimulus   = application

export { application }
