import { Controller as StimulusController } from "@hotwired/stimulus"

export default class extends StimulusController {
  connect() {
    this.setup()
  }

  setup() {
    const toolbar = this.element.toolbarElement

    if (toolbar.querySelector('#note-buttons')) { return }

    const blockGroup = this.element.toolbarElement.querySelector("[data-trix-button-group='block-tools']")
    const buttonGroup = `
      <span class="trix-button-group" id="note-buttons">
        <button type="button" class="trix-button" data-trix-attribute="note" title="note">Note</button>
        <button type="button" class="trix-button" data-trix-attribute="noteCaution" title="Caution Note">Caution</button>
        <button type="button" class="trix-button" data-trix-attribute="noteWarning" title="Warning Note">Warning</button>
      </span>
    `

    blockGroup.insertAdjacentHTML("afterend", buttonGroup)
  }
}
