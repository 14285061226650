import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import TutorialStepFactory from '../lib/interactive_tutorial/tutorial_step_factory'
import ClickActionStep from '../lib/interactive_tutorial/step_types/click_action_step'
import CloseStep from '../lib/interactive_tutorial/step_types/close_step'
import legacyFocusElementFinder from '../lib/interactive_tutorial/legacyFocusElementFinder'

export default class extends Controller {
  static targets = ['iframe', 'highlightElement', 'clickElement', 'continueButton', 'iframeWrapper']
  static values = {
    nextUrl: String,
    step: Object,
    forGuideImage: Boolean
  }

  #currentStep

  connect() {
    this.updateElementPositions = this.updateElementPositions.bind(this)
    this.visitNextStep = this.visitNextStep.bind(this)
  }

  disconnect() {
    this.teardownIframeListeners()
  }

  contentLoaded() {
    this.setup()

    if (this.isCloseType) {
      // Close steps have a fake header that gets scrolled offscreen for some reason.
      // Since the close action is at the top of the page however, we should not need to scroll at all.
      this.ready()
    } else {
      // Scroll controller will trigger `ready` when it's done
      this.dispatch('scroll', {detail: { hotspotBoundingBox: this.focusElementBoundingBox() }})
    }
  }

  ready() {
    this.dispatch('ready')
  }

  visitNextStep() {
    if (this.hasContinueButtonTarget) {
      this.continueButtonTarget.click()
    } else{
      Turbo.visit(this.nextUrlValue)
    }
  }

  updateElementPositions() {
    this._focusElementBoundingBox = undefined
    this.#currentStep.handleFocusElementPositionChange(this.focusElementBoundingBox())
  }

  setFocusElementFromHtml() {
    if (this.stepValue.xpath) {
      const xPathResult = this.iframeDocument.evaluate(this.stepValue.xpath, this.iframeDocument, null, XPathResult.FIRST_ORDERED_NODE_TYPE)
      this.focusElement = xPathResult.singleNodeValue /* might be NULL */
    } else {
      this.focusElement = legacyFocusElementFinder(this.clickedElementNodes, this.iframeTarget.contentDocument.children[0])
    }

    if (this.focusElement) {
      this.focusElement.classList.add('tutorial-practice__focus-element')
    }
  }

  /**
   * Returns the bounding rectangle of the focus element relative to the iframe origin. If the content is an image,
   * the location data can be grabbed off the step data. If the content is html, it uses the bounding box of
   * the focus element which is located in the iframe DOM.
   * @returns {{top, left, width, height}}
   */
  focusElementBoundingBox() {
    if (this._focusElementBoundingBox === undefined) {
      if (this.isImageStep) {
        const stepValue = this.stepValue

        const processedStepValue = Object.entries(stepValue).reduce((acc, [key, value]) => {
          const converted_value = Number(value)
          if (isNaN(converted_value)) {
            acc[key] = value
          } else {
            acc[key] = Number(converted_value)
          }
          return acc
        }, {})

        const { action_x: left, action_y: top, action_width: width, action_height: height } = processedStepValue

        // Adjust values if the body of the iframe is scrolled
        const contentBody = this.iframeDocument.body
        const adjustedLeft = left - contentBody.scrollLeft
        const adjustedTop = top - contentBody.scrollTop

        const bottomAvailableSpace = this.iframeWindow.innerHeight - (top + height)
        this._focusElementBoundingBox = { left: adjustedLeft, top: adjustedTop, width, height, bottomAvailableSpace }
      } else {
        const { left, top, width, height, bottom } = this.focusElement?.getBoundingClientRect() || { left: 0, top: 0, width: 0, height: 0, bottom: 0 }
        const bottomAvailableSpace = this.iframeWindow.innerHeight - bottom
        this._focusElementBoundingBox =  { left, top, width, height, bottomAvailableSpace }
      }
    }

    return this._focusElementBoundingBox
  }

  setup() {
    this.iframeWindow.addEventListener('scroll', this.updateElementPositions, true)
    this.iframeWindow.addEventListener('resize', this.updateElementPositions, true)

    if (!this.isImageStep) this.setFocusElementFromHtml()

    const stepFactory = new TutorialStepFactory(this.iframeWrapperTarget, this.focusElement, this.stepValue, this.visitNextStep, this.focusElementBoundingBox())

    if (this.forGuideImageValue) {
      this.#currentStep = stepFactory.makeStep(this.isCloseType ? CloseStep : ClickActionStep)
    } else {
      this.#currentStep = stepFactory.makeStep()
    }

    this.#currentStep.render()
  }

  teardownIframeListeners() {
    this.iframeWindow?.removeEventListener('scroll', this.updateElementPositions, true)
    this.iframeWindow?.removeEventListener('resize', this.updateElementPositions, true)
  }

  // The Legacy capture app stored the path to the clicked element as a string of element indexes
  get clickedElementNodes() {
    return this.stepValue?.clicked_element_nodes?.split(',') || []
  }

  get isImageStep() {
    return this.stepValue.action_width
  }

  get isCloseType() {
    return this.stepValue.type === 'close'
  }

  get iframeWindow() {
    return this.iframeTarget.contentWindow
  }

  get iframeDocument() {
    return this.iframeTarget.contentDocument
  }
}
