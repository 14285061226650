export default function legacyFocusElementFinder(clickedNodes, rootElement) {
  let focusElement = rootElement
  for (const index of clickedNodes) {
    if (focusElement) {
      const element = focusElement.children[index]
      if (element) {
        focusElement = element
      } else {
        break
      }
    }
  }

  return focusElement
}
