import { Controller as StimulusController } from "@hotwired/stimulus"
import { visit } from "@hotwired/turbo"

export default class TurboFrameRequest extends StimulusController {
  static targets = ['input']
  static values = {
    url: String,
    frame: String,
  }

  perform() {
    const url = new URL(this.urlValue)
    const params = new URLSearchParams(url.search)

    this.inputTargets.forEach(input => params.append(input.name, input.value))

    visit(url.pathname + '?' + params, { frame: this.frameValue })
  }
}
