import { Controller as StimulusController } from "@hotwired/stimulus"
import { saveAs } from 'file-saver'

export default class BlazerDashboardController extends StimulusController {
  static targets = ['query', 'button']

  connect() {
    this._disableButtonWhenNoTargets()
  }

  queryTargetConnected(_queryTargetElement) {
    this._disableButtonWhenNoTargets()
  }

  queryTargetDisconnected(_queryTargetElement) {
    this._disableButtonWhenNoTargets()
  }

  async downloadAll(event) {
    event.preventDefault()
    event.stopPropagation()
    const promises = this.queryTargets.map(queryForm => this.downloadQuery(queryForm))
    await Promise.all(promises)
  }

  async downloadQuery(queryForm) {
    const formData = new FormData(queryForm);
    const response = await fetch(queryForm.action,
      {
        method: "POST",
        body: formData
      }
    )
    return this.saveBlob(response)
  }

  async saveBlob(response) {
    const blob = await response.blob()
    const filename = response.headers.get('content-disposition').match(/filename=['"]?([^;'"]+)['"]?/)[1] || 'query.csv'
    return saveAs(blob, filename)
  }

  _disableButtonWhenNoTargets() {
    this.hasQueryTarget ?
      this.buttonTarget.classList.remove('disabled') :
      this.buttonTarget.classList.add('disabled')
  }
}
