import { Controller as StimulusController } from "@hotwired/stimulus"

export default class ScrollToModuleController extends StimulusController {
  static targets = ['modulesContainer', 'moduleItems']

  connect() {
    this.updateScroll();
  }

  updateScroll() {
    const moduleList = this.moduleItemsTargets;
    for (let i = 1; i < moduleList.length; i++) {
      if (moduleList[i].classList.contains('active')) {
        this.modulesContainerTarget.scrollTo(0, moduleList[i - 1].offsetTop);
        break;
      }
    }
  }
}