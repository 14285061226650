import { Controller as StimulusController } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class StepEditorImageImport extends StimulusController {
  static targets = ["stepContainer"]
  static values = {
    url: String,
    count: {
      type: Number,
      default: 0,
    }
  }

  async process({detail: { blob }}) {
    const body = new FormData()
    body.append("tutorial_step[contents]", blob.signed_id)

    const response = await post(this.urlValue, { body })
    if (response.ok) {
      const step = await response.text
      this.render(step)
      this.countValue += 1
    } else {
      console.error(response)
    }
  }

  render(step_html) {
    this.stepContainerTarget.insertAdjacentHTML('beforeend', step_html)
  }
}