import { Controller as StimulusController } from "@hotwired/stimulus"


/**
 * Stimulus controller that adds a submits a search field form when the html clear button is pressed
 */
export default class SearchFieldController extends StimulusController {
  connect() {
    this.element.addEventListener('search', this._onClear.bind(this))
  }

  _onClear(event) {
    if (event.target.value === '') {
      this.element.closest('.simple_form.search').submit()
    }
  }
}
