import { Controller as StimulusController } from "@hotwired/stimulus"
import csrfToken from '../lib/csrfToken'

export default class ImportStudentsController extends StimulusController {
  static targets = ['summary']
  static values = {
    url: String
  }

  parseFiles(event) {
    const { attachments } = event.detail
    const formData = new FormData()
    attachments.forEach(attachment => {
      formData.append('files[]', attachment.file)
    })
    fetch(
      this.urlValue,
      {
        method: 'POST',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      }
    ).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
  }
}
