import { Controller as StimulusController } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'


export default class AutoSuggestController extends StimulusController {
  static values = {
    customOptionsAllowed: Boolean,
  }

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        hideSelected: true,
        closeOnSelect: !this.element.multiple,
        openPosition: 'auto',
      },
      events: {
        addable: this.customOptionsAllowedValue ? this.addUserProvidedOptions.bind(this) : null,
      }
    })
  }

  addUserProvidedOptions(value) {
    // return false or null if you do not want to allow value to be submitted
    // if (value === 'bad') {return false}
    return value
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
