'use strict';

import Trix from "trix"

// Create custom elements for trix that we can style
// Need each of these classes because `define` will throw
// an error if it detects the same constructor more than once
// See https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define#exceptions
class NoteDefault extends HTMLDivElement {}
class NoteCaution extends HTMLDivElement {}
class NoteWarning extends HTMLDivElement {}

window.customElements.define('note-default', NoteDefault, { extends: 'div' })
window.customElements.define('note-caution', NoteCaution, { extends: 'div' })
window.customElements.define('note-warning', NoteWarning, { extends: 'div' })

const tagName = (element) => element?.tagName?.toLowerCase()

document.addEventListener("trix-before-initialize", () => {
  Trix.Attachment.previewablePattern = /^image(\/(png|jpe?g|svg\+xml|pdf|html)|$)/

  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
  }

  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
  }

  Trix.config.blockAttributes.note = {
    tagName: "note-default",
    terminal: true,
    group: false,
  }

  Trix.config.blockAttributes.noteCaution = {
    tagName: "note-caution",
    terminal: true,
    group: false,
  }

  Trix.config.blockAttributes.noteWarning = {
    tagName: "note-warning",
    terminal: true,
    group: false,
  }

  Trix.config.textAttributes.underline = {
    tagName: "u",
    inheritable: 1
  }
})

document.addEventListener("trix-file-accept", (event) => {
  const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'application/pdf']

  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only JPEG, JPG, PNG, SVG, or PDF files are supported")
  }
})

document.addEventListener("trix-initialize", (event) => {
  const toolbar = event.target.toolbarElement

  if (toolbar.querySelector("[data-trix-attribute='underline']")) { return }

  const underlineButton = '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="Underline">Underline</button>'
  const linkButton = toolbar.querySelector("[data-trix-attribute='href']")
  linkButton.insertAdjacentHTML("afterend", underlineButton)
})
