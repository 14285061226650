import InteractiveTutorialStep from './interactive_tutorial_step'
import { createElement, setElementDimensions } from '../../../helpers/dom_helpers'

class CloseStep extends InteractiveTutorialStep {
  handleFocusElementPositionChange() {}

  /**
   * Render a fake browser tab with a button to close it above the iframe. It renders its own clickable 'focus element'
   * that looks like the focus element in other step types. When the user clicks that button, it proceeds to the
   * next step and removes the fake browser head from the dom.
   */
  render() {
    this._renderFakeBrowserHead()
    super.render()
    this._updateIframeToRefreshPosition()
  }

  /**
   * The close step deviates from other steps in that in renders its focus element outside of the iframe in a fake
   * browser tab.
   * @Override
   */
  initializeFocusElementHighlight() {
    this._focusElementHighlight = createElement(this._fakeBrowserTab, 'div', 'tutorial-practice__focus-element-highlight')
    this._focusElementHighlight.dataset.interactiveTutorialTarget = 'highlightElement'
    this._fakeCloseIcon = createElement(this._focusElementHighlight, 'span', 'fake-browser-head__close-icon material-icons material-icons--lg')
    this._fakeCloseIcon.textContent = 'cancel' // Set material icon for close button
    // Override some styles that the default focus element highlight gets so that it positions correctly in the tab and is clickable
    this._focusElementHighlight.style.position = 'inherit'
    this._focusElementHighlight.style.marginRight = '12px'
    this._focusElementHighlight.style.marginTop = '9px'
    this._focusElementHighlight.style.pointerEvents = 'unset'
    setElementDimensions(this._focusElementHighlight, 32, 30)

    // click event on fake close icon advances the scene. This is styled to look like the simplify-overlay
    this._fakeCloseIcon.addEventListener('click', (_e) => {
      this._fakeBrowserHead.remove()
      this.onComplete()
    })
  }

  /**
   * This is a workaround for a bug that caused the fake browser head to be hidden behind the iframe and not cause it
   * be pushed down. It was found that toggling the display of the element caused the iframe to update and appropriately
   * be pushed down
   * @private
   */
  _updateIframeToRefreshPosition() {
    let iframe = this.parentElement().querySelector('iframe')
    setTimeout(() => iframe.style.display = 'none', 100)
    setTimeout(() => iframe.style.display = 'block', 200)
  }

  _renderFakeBrowserHead() {
    if (document.querySelector('.fake-browser-head')) { return }

    // Create a div to hold the fake browser head and insert it before the iframe so that it will show above it
    this._fakeBrowserHead = createElement(this.parentElement(), 'div', 'fake-browser-head')
    this.parentElement().insertBefore(this._fakeBrowserHead, this.parentElement().childNodes[0])

    this._renderFakeBrowserTab()
    this._renderFakeBrowserSearchBar()
    this._renderFakeButtons()
  }

  _renderFakeBrowserTab() {
    this._fakeBrowserTab = createElement(this._fakeBrowserHead, 'div', 'fake-browser-head__tab')
    createElement(this._fakeBrowserHead, 'img', 'fake-browser-head__os-icons')
    this._fakeToolbar = createElement(this._fakeBrowserHead, 'div', 'fake-browser-head__toolbar')
    createElement(this._fakeBrowserTab, 'img', 'fake-browser-head__favicon')
    const fakeBrowserTabLabel = createElement(this._fakeBrowserTab, 'span', 'fake-browser-head__tab-label')
    fakeBrowserTabLabel.textContent = 'Maverick Training'
  }

  _renderFakeBrowserSearchBar() {
    createElement(this._fakeToolbar, 'img', 'fake-browser-head__arrows')
    this._fakeUrlContainer = createElement(this._fakeToolbar, 'div', 'fake-browser-head__search-bar')
    this._fakeUrlInput = createElement(this._fakeUrlContainer, 'input', 'fake-browser-head__search-input')
    this._fakeUrlInput.value = 'https://training.mavericksolutions.com'
    this._fakeUrlInput.disabled = true
    createElement(this._fakeUrlContainer, 'img', 'fake-browser-head__star')
  }

  _renderFakeButtons() {
    const fakeUserBadge = createElement(this._fakeToolbar, 'div', 'fake-browser-head__user-badge')
    fakeUserBadge.textContent = 'M'
    createElement(this._fakeToolbar, 'div', 'fake-browser-head__hamburger-menu')
  }
}

export default CloseStep
