import { Controller as StimulusController } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import { patch } from '@rails/request.js'

export default class StepEditorSave extends StimulusController {
  static targets = ["contentFrame", "input", "form"]
  static values = {
    url: String,
    count: {
      type: Number,
      default: 0,
    },
    ready: {
      type: Boolean,
      default: false,
    }
  }

  connect() {
    if (this.contentDocument?.readyState === 'complete') {
      this.contentIsLoaded()
    }
  }

  contentIsLoaded() {
    this.readyValue = true
  }

  async perform({params: { contentType }}) {
    if (!this.readyValue) return

    this.readyValue = false
    this.dispatch('begin')

    const blob = await this.blobFor(contentType)
    const upload = new DirectUpload(blob, this.urlValue)
    upload.create(this._uploadCallback.bind(this))
  }

  async blobFor(contentType) {
    let blob
    if (contentType === 'HTML') {
      blob = new Blob([this.contentDocument.documentElement.outerHTML.toString()], {type: 'text/html'});
      blob.name = 'contents.html'
    } else {
      blob = await this._getCanvasBlob()
      blob.name = 'contents.png'
    }
    return blob
  }

  get contentDocument() {
    return this.contentFrameTarget.contentDocument
  }

  async _uploadCallback(error, blob) {
    if (error) return this._saveError(error)

    this.inputTarget.value = blob.signed_id
    const response = await patch(this.formTarget.action, {body: new FormData(this.formTarget)})

    if (response.ok) {
      this.countValue += 1
      this.readyValue = true
      this.dispatch('success')
    } else {
      this._saveError(response)
    }
  }

  _saveError(error) {
    console.error(error)
    this.dispatch('failure', error)
    this.readyValue = true
  }

  _getCanvasBlob() {
    return new Promise(resolve => this.contentFrameTarget.toBlob(blob => resolve(blob)))
  }
}
