import CaptureController from "./capture_controller.js"

export default class ImportController extends CaptureController {
  static targets = ['template', 'container']
  static values = { startPosition: Number }

  process({detail: { steps }}) {
    steps.forEach((step, index) => {
      const newStepFrame = this.cloneStepTemplate(step, index)

      this.containerTarget.insertAdjacentElement('beforeend', newStepFrame)
    });
  }

  cloneStepTemplate(step, index) {
    const position = index + this.startPositionValue + 1
    const frame = this.templateTarget.content.firstElementChild.cloneNode(true)
    frame.id = `tutorial_step_${step.id}`
    frame.firstElementChild.setAttribute('data-capture--upload-step-id-value', step.id)
    frame.firstElementChild.setAttribute('data-capture--upload-step-xpath-value', step.xPath)
    frame.firstElementChild.setAttribute('data-capture--upload-step-element-tag-value', step.elementTag)
    frame.firstElementChild.setAttribute('data-capture--upload-step-position-value', position)
    frame.firstElementChild.appendChild(this.imageFor(step.dataUrl))
    return frame
  }

  imageFor(imageData) {
    const image = document.createElement('img')
    image.src = imageData
    image.classList.add('step-image-preview')
    return image
  }
}
