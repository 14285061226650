import { Controller as StimulusController } from "@hotwired/stimulus"
import Trix from 'trix'
import { post } from '@rails/request.js'

export default class extends StimulusController {
  static values = {
    url: String,
  }

  connect() {
    this.setup()
  }

  setup() {
    if (this.toolbar.querySelector('.trix-button--icon-table')) { return }

    const buttonHTML = `
      <button type="button"
        class="trix-button trix-button--icon trix-button--icon-table"
        title="table"
        tabindex="-1"
        data-action="trix--tables#attachTable">
        Attach Table
      </button>`

    this.toolbar.querySelector("[data-trix-button-group=file-tools]").insertAdjacentHTML("beforeend", buttonHTML)
  }

  async attachTable(event) {
    const response = await post(this.urlValue);

    if (response.ok) {
      const tableAttachment = await response.json;
      this.insertTable(tableAttachment);
    } else {
      // error handling
    }
  }

  insertTable(tableAttachment) {
    this.attachment = new Trix.Attachment(tableAttachment);
    this.editor.insertAttachment(this.attachment);

    this.element.focus();
  }

  get editor() {
    return this.element.querySelector('trix-editor').editor
  }

  get toolbar() {
    return this.element.querySelector('trix-toolbar')
  }
}
