import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.setLinks()
  }

  setLinks() {
    const trixLinks = this.element.querySelectorAll('a')
    trixLinks.forEach(link => {
      link.setAttribute('target', '_blank')
    })
  }
}
