import InteractiveTutorialStep from './interactive_tutorial_step'
import { setElementLocation, setElementDimensions, numberFromPixels } from '../../../helpers/dom_helpers'

class ClickActionStep extends InteractiveTutorialStep {
  #minSizeOfFocusElementOverlay = 40

  render() {
    super.render()
    this.initializeFocusElementClickTarget()
  }

  handleFocusElementPositionChange(focusElementBoundingBox) {
    this._focusElementBoundingBox = focusElementBoundingBox
    super.handleFocusElementPositionChange()

    this.updateFocusElementClickTargetSize()
    this.updateFocusElementClickTargetPosition()
  }

  /**
   * Creates an invisible element over the focus element and the focus element highlight that is clickable.
   * It has a min height and width of 40px to make small buttons easier to click
   */
  initializeFocusElementClickTarget() {
    this.focusElementOverlay = this.parentElement().appendChild(document.createElement('a'))
    this.focusElementOverlay.onclick = this.onComplete.bind(this)
    this.focusElementOverlay.className = 'tutorial-practice__focus-element-click-target'
    this.focusElementOverlay.dataset.interactiveTutorialTarget = 'clickElement'

    this.updateFocusElementClickTargetSize()
    this.updateFocusElementClickTargetPosition()
  }

  /**
   * This function will position the invisible focus element click target over the focus element.
   * Some math is required due to the possibility of there being an adjusted width or height on the clickable
   * overlay to allow for easier clicking of smaller elements.
   */
  updateFocusElementClickTargetPosition() {
    let { width: overlayElementWidth, height: overlayElementHeight } = this.focusElementOverlay.style
    let { top: adjustedTop, left: adjustedLeft, width: focusElementWidth, height: focusElementHeight } = this.focusElementBoundingBox()
    overlayElementWidth = numberFromPixels(overlayElementWidth)
    overlayElementHeight = numberFromPixels(overlayElementHeight)
    adjustedTop = adjustedTop - ((overlayElementHeight - focusElementHeight) / 2)
    adjustedLeft = adjustedLeft - ((overlayElementWidth - focusElementWidth) / 2)
    setElementLocation(this.focusElementOverlay, adjustedLeft, adjustedTop)
  }

  updateFocusElementClickTargetSize() {
    const { width, height } = this.focusElementBoundingBox()

    setElementDimensions(
      this.focusElementOverlay,
      Math.max(width, this.#minSizeOfFocusElementOverlay),
      Math.max(height, this.#minSizeOfFocusElementOverlay)
    )
  }
}

export default ClickActionStep
