import { Controller } from "@hotwired/stimulus"
import { animate } from '../helpers/dom_helpers'

export default class extends Controller {
  static targets = ['message']

  messageTargetConnected(target) {
    const timerId = setTimeout(() => target.remove(), 10_000)
    target.dataset.timerId = timerId
  }

  remove({ currentTarget }) {
    const message = currentTarget.closest('.flash')
    clearTimeout(message.dataset.timerId)

    animate(message, 'fadeOutRightBig')
    setTimeout(() => message.remove(), 1_000)
  }
}
