import SortableLibraryItemsController from './sortable_library_items_controller'

/**
 * Specialized library items controller for new library items in content management panel
 * that can be added to a library
 */
export default class ContentManagementController extends SortableLibraryItemsController {
  /**
   * @param  {HTMLNode} {item The element you dragged
   * @param  {Integer} newIndex The index within the to element of where you dropped the dragged element
   * @param  {HTMLNode} to The folder you dropped the dragged element into
   * @param  {HTMLNode} from} The folder the dragged element came from
   * @description Called when you drop a dragged element. Makes ajax request to
   * persist the change on the server
   * @override
   */
  onEnd({ item, newIndex, from , to}) {
    const form = item.querySelector(`#create-folder-member-form-${item.dataset.itemId}`)
    const dropTargetInContentArea = to.closest('#content-management-panel')

    if (!form || dropTargetInContentArea) {
      return
    }

    this._updateFolderEmptyStatus(to)
    this._updateCreateSubFolderButton(to)
    this._hideEmptySearchResult(from)

    form.querySelector('#folder_member_folder_id').value = to.dataset.parentFolderId
    const dragTargetContainer = to.closest('.draggable-folders')
    form.querySelector('#folder_member_status').value = dragTargetContainer.dataset['parentFolderStatus']
    form.querySelector('#folder_member_container_id').value = dragTargetContainer.getAttribute('data-parent-container-id')
    form.querySelector('#folder_member_container_type').value = dragTargetContainer.getAttribute('data-parent-container-type')
    form.querySelector('#folder_member_position').value = newIndex + 1
    form.requestSubmit()
  }

  _hideEmptySearchResult(from) {
    from.classList.add('animate__animated')
    from.classList.add('animate__fadeOut')
    setTimeout(() => from.remove(), 500)
  }

  // You can set default options in this getter for all sortable elements.
  /**
   * Default options used by SortableJS
   */
  get defaultOptions() {
    return {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: {
        name: 'library',
        put: false
      },
      sort: false,
      ghostClass: 'is-dragged',
      onMove: this.onMove.bind(this),
      onEnd: this.onEnd.bind(this),
      filter: '.content-management-panel__folder-name',
      preventOnFilter: false, // Call `event.preventDefault()` when triggered `filter`
    }
  }
}
