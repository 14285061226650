import { Controller as StimulusController } from "@hotwired/stimulus"

export default class ImagePreviewController extends StimulusController {
  static targets = [ 'input', 'preview' ]

  preview() {
    const input = this.inputTarget
    const preview = this.previewTarget
    let reader

    if (input.files && input.files[0]) {
      reader = new FileReader()

      reader.onload = (e) => {
        preview.src = e.target.result
      }
      reader.readAsDataURL(input.files[0])
    }
  }
}
